import { render, staticRenderFns } from "./SolutionInfo.vue?vue&type=template&id=4bc1682c&scoped=true"
import script from "./SolutionInfo.vue?vue&type=script&lang=js"
export * from "./SolutionInfo.vue?vue&type=script&lang=js"
import style0 from "./SolutionInfo.vue?vue&type=style&index=0&id=4bc1682c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc1682c",
  null
  
)

export default component.exports