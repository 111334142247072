<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-section company-info">
                <h2 v-html="$t('companyNameFooter')"></h2>
                <p v-html="$t('address')"></p>
                <p>{{ $t('phone') }}</p>
                <p>{{ $t('email') }}</p>
            </div>
            <div class="footer-section links">
                <h3>{{ $t('links') }}</h3>
                <ul>
                    <li @click="navigateToSection('', '')"><a href="#">{{ $t('home') }}</a></li>
                    <li @click="navigateToSection('solutionInfo', '')"><a href="#">{{ $t('solutions') }}</a></li>
                    <li @click="navigateToSection('companyInfo', '')"><a href="#">{{ $t('companyInfo') }}</a></li>
                    <li @click="navigateToSection('recruitmentInfo', '')"><a href="#">{{ $t('recruitmentInfo') }}</a></li>
                    <li @click="navigateToSection('contact', '')"><a href="#">{{ $t('contact') }}</a></li>
                </ul>
            </div>
        </div>
        <div class="footer-bottom">
            <p>{{ $t('copyright') }}</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterMenu',
    methods: {
        navigateToSection(router, subSectionId) {
            const targetPath = `/${router}`;
            const hash = subSectionId ? `#${subSectionId}` : '';
            const currentPath = this.$route.path;
            const currentHash = window.location.hash;

            if (currentPath !== targetPath) {
                this.$router.push(targetPath).then(() => {
                    if (subSectionId) {
                        location.hash = hash;
                    }
                });
            } else if (currentHash !== hash) {
                this.$router.replace({ hash: hash }).then(() => {
                    const element = document.getElementById(subSectionId);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            } else if (subSectionId && currentHash === hash) {
                const element = document.getElementById(subSectionId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.footer {
    background-color: #fff;
    padding: 2rem 0;
    color: #fff;
    background-color: #414c64e3;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;

    &-content {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;

        .footer-section {
            flex: 1;
            margin-right: 2rem;

            &.links {
                position: relative;
                right: 15rem;

                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        width: 7rem;
                        margin-bottom: 0.5rem;
                        cursor: pointer;

                        a {
                            color: #fff;
                            text-decoration: none;
                            font-size: 14px;
                            font-weight: 400;
                            font-family: 'Noto Sans JP', sans-serif;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            h2,
            h3 {
                margin-bottom: 1rem;
                font-weight: 400;
                font-size: 28px;
            }

            p {
                margin: 0.5rem 0;
                font-size: 14px;
            }
        }
    }

    &-bottom {
        text-align: center;
        padding: 1rem 0;
        margin-top: 2rem;

        p {
            margin: 0;
            font-family: 'Noto Sans JP', sans-serif;
        }
    }
}

@media (max-width: 768px) {
    .footer {
      &-content {
        padding-left: 10%;
        flex-direction: column; /* 垂直排列 */
        align-items: left; /* 居中对齐 */
        text-align: left; /* 居中文本 */
      }
  
      .footer-section {
        margin: 0 0 1rem 0; /* 调整间距 */
  
        &.links {
            position: relative;
            left: 0;

            ul {
                list-style: none;
                padding: 0;

                li {
                    width: 7rem;
                    margin-bottom: 0.5rem;
                    cursor: pointer;

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 400;
                        font-family: 'Noto Sans JP', sans-serif;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
  
        h2,
        h3 {
          font-size: 22px; /* 调整标题字体大小 */
        }
  
        p {
          font-size: 15px; /* 调整段落字体大小 */
        }
      }
    }
}
</style>