<template>
  <div>
    <transition name="fade">
      <section v-show="!loading" class="banner">
        <div class="overlay"></div>
        <h1>{{ $t('bannerTitle') }}</h1>
        <h2>{{ $t('bannerSubtitle') }}</h2>
      </section>
    </transition>
    <transition name="fade">
      <section v-show="!loading" class="services">
        <!-- Greeting Section -->
        <transition name="fade">
          <section v-show="!loading" id="greeting" class="section greeting" v-observe-visibility="onVisibilityChange">
            <div class="greeting-content">
              <div class="greeting-header">
                <h2>{{ $t('greetingTitle') }}</h2>
              </div>
              <div class="greeting-text">
                <p v-html="$t('greetingMission')"></p>
                <p v-html="$t('greetingService')"></p>
                <p v-html="$t('greetingEmployee')"></p>
                <p v-html="$t('greetingFuture')"></p>
              </div>
              <img :src="preloadedImages.hand1" alt="Company Profile" class="img">
            </div>
          </section>
        </transition>
      </section>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    companyLogo: {
      type: String,
      default: ''
    },
    percentage: {
      type: Number,
      default: 0
    },
    preloadedImages: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentIndex: 0,
      maxBlur: 8, // 最大模糊值
    };
  },
  watch: {
  },
  methods: {
    onVisibilityChange(isVisible, entry) {
      if (isVisible) {
        entry.target.classList.add('visible');
      } else {
        entry.target.classList.remove('visible');
      }
    },
    prev() {
      this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    },
    handleScroll() {
      const banner = document.querySelector('.banner');
      const overlay = document.querySelector('.banner .overlay');
      const maxBlur = this.maxBlur;
      const scrollTop = window.scrollY;
      const bannerHeight = banner.offsetHeight;

      // 计算模糊值
      const blurValue = Math.min((scrollTop / bannerHeight) * maxBlur, maxBlur);
      banner.style.filter = `blur(${blurValue}px)`;

      // 计算透明度值
      const maxOpacity = 0.9;
      const minOpacity = 0.3;
      const opacityValue = Math.min(minOpacity + (scrollTop / bannerHeight) * (maxOpacity - minOpacity), maxOpacity);
      overlay.style.background = `rgba(0, 0, 0, ${opacityValue})`;

      // 固定背景位置
      if (scrollTop < bannerHeight) {
        banner.style.position = 'fixed';
        banner.style.top = '0';
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed; /* 背景图片固定在顶部 */
  top: 0;
  left: 0;
  width: 100%;
  background: url('../assets/img/city.webp') center/cover no-repeat;
  height: 100vh;
  text-align: center;
  color: #ffffff;
  font-family: 'Noto Sans JP', sans-serif;
  z-index: -1; /* 确保背景在内容后面 */
}

.banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* 初始半透明的黑色覆盖层 */
}

.banner h1,
.banner h2 {
  position: relative;
  z-index: 1;
  margin: 0;
}

.banner h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.banner h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.services {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fff; /* 浅灰色 */
  padding: 50px 0;
  text-align: center;
  margin-top: 100vh; /* 确保服务部分从背景图片下方开始 */
  position: relative;
  z-index: 1; /* 确保服务部分位于背景图片上方 */
}

.services h2 {
  font-size: 28px;
  margin-bottom: 40px;
}

.service {
  font-family: 'Noto Sans JP', sans-serif;
  display: inline-block;
  width: calc(33.33% - 30px); /* 考虑 margin 的宽度 */
  margin: 0 15px; /* 确保元素之间的间距 */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s, transform 0.6s;
}

.service.visible {
  opacity: 1;
  transform: translateY(0);
}

.service img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
}

.service h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.service p {
  font-size: 16px;
  color: #666;
}

.greeting {
  width: 60%;
  margin: 0 auto; /* 居中 */
  font-family: 'Noto Sans JP', sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  padding: 60px;
  text-align: center;
  opacity: 1; /* 确保元素总是可见 */
  transform: translateY(0); /* 确保元素没有平移 */
  transition: none; /* 移除过渡效果 */
  padding-top: 30px;  /* 添加顶部间距 */
  margin-top: -30px;
  margin-bottom: 14vh;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .greeting-header {
    width: 100%;
    margin: 40px auto -20px auto;
    text-align: center;

    h2 {
      font-size: 40px;
      font-family: 'Noto Sans JP', sans-serif;
      color: #414c64;
    }
  }

  .greeting-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    img {
      width: 75%;
      margin-top: -20px;
    }

    .greeting-text {
      font-family: 'Noto Sans JP', sans-serif;
      color: #414c64;
      flex: 1;
      min-width: 300px;
      text-align: left;
      line-height: 28px;
      padding-left: 7.8%;
      padding-right: 1%;

      p {
        font-size: 15px;
        margin-bottom: 25px;
        opacity: 1; /* 确保段落总是可见 */
        transform: translateY(0); /* 确保段落没有平移 */
        transition: none; /* 移除过渡效果 */

        strong {
          color: #2d3647;
          font-size: 18px;
          font-weight: 600;
          position: relative;
          line-height: 34px;
        }
      }
    }

    .greeting-image {
      position: relative;
      top: 25px;
      padding-left: 20px;
      max-width: 300px;
      width: 100%;
      text-align: center;
      padding-right: 7.8%;

      img {
        width: 100%;
        height: auto;
      }

      .signature {
        color: #2d3647;
        font-weight: 600;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner {
    height: 100vh; // 高度调整以适应移动端

    h1 {
      font-size: 24px; // 在移动端调整h1字体大小
    }

    h2 {
      font-size: 18px; // 在移动端调整h2字体大小
    }
  }

  .services h2 {
    font-size: 24px; // 在移动端调整h2字体大小
  }

  .service {
    width: 100%;
    margin-bottom: 20px;
  }

  .greeting {
    width: 90%;
    padding: 2%;
    box-sizing: border-box; /* 确保内边距不会影响元素宽度 */

    .greeting-header h2 {
      font-size: 24px; // 在移动端调整h2字体大小
    }

    .greeting-content {

      .greeting-text {
        padding: 0;
      }
  
      img {
        width: 100%;
        height: auto;
        padding-top: 15px;
      }
    }
  }
}
</style>
