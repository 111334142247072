<template>
  <div class="company-info" v-show="!loading">
    <!-- Banner -->
    <div class="banner">
      <img :src="preloadedImages.company1" alt="Company Profile" class="banner-img">
      <div class="overlay"></div>
      <div class="banner-text">
        <h1>{{ $t('companyInfo') }}</h1>
      </div>
    </div>

    <!-- Achievements Section -->
    <!-- <div id="achievements" class="section achievements" v-observe-visibility="onVisibilityChange">
      <div class="achievements-header">
        <h2>{{ $t('achievementsTitle') }}</h2>
      </div>
      <div class="achievements-content">
        <p v-html="$t('achievementsMission')"></p>
        <p v-html="$t('achievementsService')"></p>
        <p v-html="$t('achievementsEmployee')"></p>
      </div>
    </div> -->

    <!-- Vision Section -->
    <div id="vision" class="section vision" v-observe-visibility="onVisibilityChange">
      <div class="vision-header">
        <h2>{{ $t('visionTitle') }}</h2>
      </div>
      <div class="vision-content">
        <p v-html="$t('visionMission')"></p>
        <p v-html="$t('visionService')"></p>
        <p v-html="$t('visionEmployee')"></p>
      </div>
      <img :src="preloadedImages.company2" alt="Company Profile" class="img" v-if="preloadedImages.company2">
    </div>

    <!-- Company Profile Section -->
    <div id="companyprofile" class="section companyprofile" v-observe-visibility="onVisibilityChange">
      <h2>{{ $t('companyProfileTitle') }}</h2>
      <table class="company-table">
        <tr>
          <td class="first-child">{{ $t('companyNameLabel') }}</td>
          <td>{{ $t('companyName') }}</td>
        </tr>
        <tr>
          <td class="first-child">{{ $t('capitalLabel') }}</td>
          <td>{{ $t('capital') }}</td>
        </tr>
        <tr>
          <td class="first-child">{{ $t('ceoLabel') }}</td>
          <td>{{ $t('ceoName') }}</td>
        </tr>
        <tr>
          <td class="first-child">{{ $t('foundedLabel') }}</td>
          <td>{{ $t('founded') }}</td>
        </tr>
        <tr>
          <td class="first-child">{{ $t('employeesLabel') }}</td>
          <td>{{ $t('employees') }}</td>
        </tr>
        <tr>
          <td class="first-child">{{ $t('headquartersLabel') }}</td>
          <td>{{ $t('headquarters') }}</td>
        </tr>
        <tr>
          <td class="first-child">{{ $t('phoneLabel') }}</td>
          <td>{{ $t('phone') }}</td>
        </tr>
        <tr>
          <td class="first-child" v-html="$t('invoiceLabel')"></td>
          <td v-html="$t('invoiceNumber')"></td>
        </tr>
      </table>
      <img :src="preloadedImages.company3" alt="Company Profile" class="img" v-if="preloadedImages.company3">

    </div>

    <!-- Welfare Section -->
    <div id="welfare" class="section welfare" v-observe-visibility="onVisibilityChange">
      <h2>{{ $t('welfareTitle') }}</h2>
      <div class="welfare-content">
        <div class="welfare-text">
          <h3>{{ $t('insurance') }}</h3>
          <p>{{ $t('insuranceDetails') }}</p>
          <h3>{{ $t('vacation') }}</h3>
          <p>{{ $t('vacationDetails') }}</p>
          <h3>{{ $t('allowances') }}</h3>
          <p>{{ $t('allowancesDetails') }}</p>
        </div>
        <div class="welfare-image">
          <img :src="preloadedImages.welfare" alt="Welfare Image">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { openDB } from 'idb';

const dbPromise = openDB('images-db', 1, {
  upgrade(db) {
    db.createObjectStore('images');
  },
});

async function loadImageFromIndexedDB(name) {
  const db = await dbPromise;
  const blob = await db.get('images', name);
  if (!blob) return null;
  return URL.createObjectURL(blob);
}

export default {
  name: 'CompanyInfo',
  data() {
    return {
      activeIcon: null,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    preloadedImages: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    navigateToSection(sectionId) {
      location.hash = `#${sectionId}`;
      location.hash = '';
    },
    onVisibilityChange(isVisible, entry) {
      if (isVisible) {
        entry.target.classList.add('visible');
        // Add animation for each paragraph inside the greeting section
        if (entry.target.id === 'greeting') {
          const paragraphs = entry.target.querySelectorAll('.greeting-text p');
          paragraphs.forEach((p, index) => {
            p.classList.remove('fade-in-visible');
            setTimeout(() => {
              p.classList.add('fade-in-visible');
            }, index * 150); // Adjust the delay time as needed
          });
        }
      } else {
        entry.target.classList.remove('visible');
        // Reset animation for each paragraph inside the greeting section
        if (entry.target.id === 'greeting') {
          const paragraphs = entry.target.querySelectorAll('.greeting-text p');
          paragraphs.forEach((p) => {
            p.classList.remove('fade-in-visible');
          });
        }
      }
    },
    setActiveIcon(index) {
      this.activeIcon = index;
    }
  },
  directives: {
    'observe-visibility': {
      inserted(el, binding) {
        const options = {
          threshold: 0.1 // Adjust as needed
        };
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (binding.value && typeof binding.value === 'function') {
              binding.value(entry.isIntersecting, entry);
            }
          });
        }, options);
        observer.observe(el);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.company-info {
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: hidden; /* 防止横向滚动条 */

  .banner {
    position: relative;
    width: 100%;
    height: 420px;
    overflow: hidden;
    z-index: 1;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .banner-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      
      h1 {
        font-size: 40px;
        letter-spacing: 2px;
        font-weight: 400;
        font-family: 'Noto Sans JP', sans-serif;
      }
    }
  }

  .section {
    width: 60%;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    margin-bottom: 14vh;

    img {
      padding-top: 30px;
      width: 70%;
    }

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &.greeting,
    &.vision,
    &.companyprofile,
    &.welfare {
      background-color: #fff;
      padding: 40px 20px;
    }

    .vision-content,
    .welfare-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;
      line-height: 28px;

      p {
        font-size: 15px;
        margin-bottom: 25px;
        color: #414c64;

        strong {
          color: #2d3647;
          font-size: 18px;
          font-weight: 600;
          line-height: 34px;
        }
      }
    }
    
    .company-table {
      width: 100%;
      border-collapse: collapse;
      tr {
        td {
          padding: 10px 0 10px 100px;
          color: #414c64;
          font-weight: 600;
          text-align: left;
          width: 30%;
        }
  
        .first-child {
          width: 14%;
          padding-left: 15%;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      
    }

    .welfare-text {
      flex: 1;
      min-width: 200px;
      text-align: left;
      line-height: 28px;
      margin-left: 15%;

      h3 {
        font-size: 20px;
        color: #414c64;
        margin-bottom: 5px;
      }

      p {
        padding-bottom: 20px;
        font-size: 15px;
        margin-bottom: 20px;
      }
    }

    .welfare-image {
      max-width: 350px;
      width: 100%;
      text-align: center;
      padding-left: 20px;
      padding-top: 24px;
      margin-right: 5vw;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        clip-path: inset(0 0 25% 0); /* 截取图像的上60% */
      }
    }
  }

  h2 {
    font-size: 40px;
    font-weight: 400;
    color: #414c64;
  }

  p {
    font-size: 15px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .company-info {
    .section {
      width: 100%;
      padding: 20px;
      box-sizing: border-box; /* 确保内边距不会影响元素宽度 */

      img {
        padding-top: 30px;
        width: 94%;
      }

      .vision-content {
        padding: 3%;
      }
      

      .company-table {
        width: 100%;
        border-collapse: collapse;
        tr {
          td {
            padding: 10px 0;
            color: #414c64;
            font-weight: 600;
            text-align: left;
            width: 250px;
          }
    
          .first-child {
            width: 100px;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }

      .welfare-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        line-height: 28px;
        padding: 0;
  
        .welfare-text {
          flex: 1;
          min-width: 100px;
          text-align: left;
          line-height: 28px;
          padding: 0 4%;
          margin-left: 0;
    
          h3 {
            font-size: 20px;
            color: #414c64;
            margin-bottom: 5px;
          }
    
          p {
            padding-bottom: 20px;
            font-size: 15px;
            margin-bottom: 20px;
          }
        }
    
        .welfare-image {
          max-width: 100%;
          text-align: center;
          
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            clip-path: inset(0 0 40% 0);
          }
        }

      }


    }

    .banner {
      height: 320px; // 高度调整以适应移动端

      .banner-text {
        h1 {
          font-size: 24px; // 字体大小调整以适应移动端
        }
      }
    }

    h2 {
      font-size: 24px; // 在移动端调整h2字体大小
    }

    .vision-content,
    .companyprofile .company-table,
    .welfare-content {
      padding: 0 20px;
      box-sizing: border-box; /* 确保内边距不会影响元素宽度 */
    }

    
    .company-table {
      width: 100%;
      border-collapse: collapse;
      
    }
  }
}
</style>
