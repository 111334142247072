<template>
  <transition name="fade">
    <div v-if="loading && cssLoaded && visible" class="loading-container">
      <div class="loading-content">
        <img :src="companyLogo" alt="Company Logo" class="company-logo"/>
        <h1 class="company-name">GrowthSoft</h1>
        <div class="loading-percentage">
          <span class="percentage">{{ displayPercentage }}%</span><span class="dots">..</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    companyLogo: {
      type: String,
      required: true
    },
    percentage: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      displayPercentage: 0,
      dots: '',
      intervalId: null,
      cssLoaded: false
    };
  },
  watch: {
    percentage(newVal) {
      this.smoothTransition(newVal);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startDotsAnimation();
      this.cssLoaded = true; // 确保CSS加载完毕后再显示内容
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    startDotsAnimation() {
      let count = 0;
      this.intervalId = setInterval(() => {
        const dotCount = (count % 4);
        this.dots = '.'.repeat(dotCount);
        count++;
      }, 500);
    },
    smoothTransition(target) {
      const step = () => {
        if (this.displayPercentage < target) {
          this.displayPercentage++;
          requestAnimationFrame(step);
        }
      };
      step();
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1000;
}

.loading-content {
  text-align: center;
  position: relative;
  top: -50px;
}

.company-logo {
  width: 100px;
  height: auto;
}

.company-name {
  margin: -10px 0 10px 0;
  color: #293244d3;
  font-weight: 400;
  font-size: 30px;
  font-family: 'Noto Sans JP', sans-serif;
}

.loading-percentage {
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  padding-left: 3px;
  color: #414c64a9;
}

.percentage {
  display: inline-block;
  width: 50px; /* 根据需要调整宽度，以适应百分比 */
  text-align: right;
}

.dots {
  display: inline-block;
  width: 20px; /* 根据需要调整宽度，以适应点点 */
  text-align: left;
  animation: blink 1s steps(1, end) infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
