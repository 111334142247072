import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import SolutionInfo from '@/components/SolutionInfo.vue';
import CompanyInfo from '@/components/CompanyInfo.vue';
import RecruitmentInfo from '@/components/RecruitmentInfo.vue';
import ContactInfo from '@/components/ContactInfo.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/solutionInfo',
      name: 'SolutionInfo',
      component: SolutionInfo
    },
    {
      path: '/companyInfo',
      name: 'CompanyInfo',
      component: CompanyInfo
    },
    {
      path: '/recruitmentInfo',
      name: 'RecruitmentInfo',
      component: RecruitmentInfo
    },
    {
      path: '/contactInfo',
      name: 'ContactInfo',
      component: ContactInfo
    }
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        const element = document.getElementById(to.hash.replace('#', ''));
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 40,
            behavior: 'smooth',
          });
        }
        resolve({ x: 0, y: element ? element.offsetTop - 40 : 0 });
      });
    }
    return { x: 0, y: 0 };
  },
});
