// src/store/index.js

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 定义你的状态
  },
  mutations: {
    // 定义你的突变
  },
  actions: {
    // 定义你的动作
  },
  modules: {
    // 定义你的模块
  }
});
