<template>
  <div>
    <transition name="fade">
      <div v-show="loading" class="loading-container">
        <LoadingAnimation
          :loading="loading"
          :companyLogo="companyLogo"
          :percentage="percentage"
          @handleLoadingComplete="handleLoadingComplete"
        />
      </div>
    </transition>
    <transition name="fade">
      <div v-show="!loading">
        <NavBar :isSideNavOpen="isSideNavOpen" @toggleSideNav="toggleSideNav" />
        <SideNav :isSideNavOpen="isSideNavOpen" @closeSideNav="closeSideNav" />
        <router-view
          :loading="loading"
          :companyLogo="companyLogo"
          :percentage="percentage"
          :preloadedImages="preloadedImages"
          @handleLoadingComplete="handleLoadingComplete"
        />
        <FooterMenu v-if="!loading" />
      </div>
    </transition>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterMenu from './components/FooterMenu.vue';
import SideNav from './components/SideNav.vue';
import LoadingAnimation from './components/LoadingAnimation.vue'; // 确保正确导入组件
import { openDB } from 'idb';

const dbPromise = openDB('images-db', 1, {
  upgrade(db) {
    db.createObjectStore('images');
  },
});

async function loadImage(name) {
  const db = await dbPromise;
  const blob = await db.get('images', name);
  if (!blob) return null;
  return URL.createObjectURL(blob);
}

export default {
  name: 'App',
  components: {
    NavBar,
    FooterMenu,
    SideNav,
    LoadingAnimation,
  },
  data() {
    return {
      loading: true, // 初始加载状态
      isSideNavOpen: false,
      companyLogo: require('./assets/icon/company.png'),
      percentage: 0,
      preloadedImages: {
        city5: null,
        phone1: null,
        welfare: null,
        work1: null,
        work2: null,
        hand1: null,
        company1: null,
        contact1: null,
        solution1: null,
        company2: null,
        company3: null,
        
      }
    };
  },
  methods: {
    handleLoadingComplete() {
      this.loading = false;
      sessionStorage.setItem('loading', 'false'); // 保存状态到 sessionStorage
    },
    toggleSideNav() {
      this.isSideNavOpen = !this.isSideNavOpen;
    },
    closeSideNav() {
      this.isSideNavOpen = false;
    },
    async preloadImages() {
      const images = [
        { name: 'city5', src: require('./assets/img/city5.webp') },
        { name: 'phone1', src: require('./assets/img/phone1.webp') },
        { name: 'welfare', src: require('./assets/img/Welfare.webp') },
        { name: 'work1', src: require('./assets/img/work1.webp') },
        { name: 'work2', src: require('./assets/img/work2.webp') },
        { name: 'hand1', src: require('./assets/img/hand1.webp') },
        { name: 'company1', src: require('./assets/img/company1.webp') },
        { name: 'contact1', src: require('./assets/img/contact1.webp') },
        { name: 'solution1', src: require('./assets/img/solution1.webp') },
        { name: 'company2', src: require('./assets/img/company2.webp') },
        { name: 'company3', src: require('./assets/img/company3.webp') },
      ];

      // 初始化时瞬间过渡到31%
      this.percentage = 31;

      let loadedCount = 0;
      const totalImages = images.length;

      for (const { name, src } of images) {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // 设置crossOrigin属性
        img.src = src;
        img.onload = async () => {
          try {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/webp');

            // 尝试存储到 IndexedDB
            const db = await dbPromise;
            const blob = await (await fetch(dataURL)).blob();
            await db.put('images', blob, name);

            this.preloadedImages[name] = dataURL;

            loadedCount++;
            this.percentage = 31 + Math.floor(47 * (loadedCount / totalImages)); // 从 31% 平滑过渡到 78%

            if (loadedCount === totalImages) {
              this.percentage = 99;
              setTimeout(() => {
                this.loading = false; // 所有图片加载完成后，隐藏加载动画
                setTimeout(() => {
                  this.$emit('handleLoadingComplete'); // 通知子组件加载完成
                }, 500);
              }, 300);
            }
          } catch (error) {
            console.error(`Error processing image ${name}:`, error);
          }
        };
        img.onerror = (e) => {
          console.error(`Error loading image ${name}:`, e);
          loadedCount++;
          if (loadedCount === totalImages) {
            this.loading = false;
            this.$emit('handleLoadingComplete');
          }
        };
      }
    },
    async checkImagesInIndexedDB() {
      const imageNames = [
        'city5',
        'phone1',
        'welfare',
        'work1',
        'work2',
        'hand1',
        'company1',
        'contact1',
        'solution1',
        'company2',
        'company3',
        
      ];
      const db = await dbPromise;
      const allImagesExist = await Promise.all(imageNames.map(async (name) => {
        const blob = await db.get('images', name);
        if (blob) {
          this.preloadedImages[name] = URL.createObjectURL(blob);
          return true;
        }
        return false;
      }));
      return allImagesExist.every(exists => exists);
    }
  },
  async mounted() {
    const loadingState = sessionStorage.getItem('loading');
    if (loadingState === 'false') {
      this.loading = false; // 从 sessionStorage 中恢复状态
    } else {
      const allImagesExist = await this.checkImagesInIndexedDB();
      if (!allImagesExist) {
        setTimeout(() => {
          this.preloadImages(); // 加载公司 icon 和预加载图片
        }, 400);
      } else {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
   /*user-select: none; 禁止文本选中 */
  -webkit-tap-highlight-color: transparent; /* 禁止移动设备点击高亮 */
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
