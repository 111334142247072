<template>
  <div class="solution-info" v-show="!loading">
    <!-- Banner -->
    <div class="banner">
      <img :src="preloadedImages.solution1" alt="Company Profile" class="banner-img" v-if="preloadedImages.solution1">
      <div class="overlay"></div>
      <div class="banner-text">
        <h1>{{ $t('solutionInfo.title') }}</h1>
      </div>
    </div>

    <!-- SES Section -->
    <div id="SES" class="section ses" v-observe-visibility="onVisibilityChange">
      <div class="ses-content">
        <h2>{{ $t('solutionInfo.ses.title') }}</h2>
        <p><strong>{{ $t('solutionInfo.overview') }}</strong><br>{{ $t('solutionInfo.ses.description') }}</p>
        <p>
          <strong>{{ $t('solutionInfo.technicalFields') }}</strong><br>
          <ul class="fields">
            <li class="field">
              <span class="field-label">{{ $t('solutionInfo.ses.frontend') }}：</span>
              <span class="field-content">{{ $t('solutionInfo.ses.frontendContent') }}</span>
            </li>
            <li class="field">
              <span class="field-label">{{ $t('solutionInfo.ses.languages') }}：</span>
              <span class="field-content">{{ $t('solutionInfo.ses.languagesContent') }}</span>
            </li>
            <li class="field">
              <span class="field-label">{{ $t('solutionInfo.ses.database') }}：</span>
              <span class="field-content">{{ $t('solutionInfo.ses.databaseContent') }}</span>
            </li>
          </ul>
        </p>
        <img :src="preloadedImages.work1" alt="Company Profile" class="img" v-if="preloadedImages.work1">
      </div>
    </div>

    <!-- App Development Section -->
    <div id="AppDev" class="section app-dev" v-observe-visibility="onVisibilityChange">
      <div class="app-dev-content">
        <h2>{{ $t('solutionInfo.appDev.title') }}</h2>
        <p><strong>{{ $t('solutionInfo.overview') }}</strong><br>{{ $t('solutionInfo.appDev.description') }}</p>
        <p><strong>{{ $t('solutionInfo.services') }}</strong><br>{{ $t('solutionInfo.appDev.services') }}</p>
        <img :src="preloadedImages.phone1" alt="Company Profile" class="img" v-if="preloadedImages.phone1">
      </div>
    </div>

    <!-- Dispatch Services Section -->
    <div id="Dispatch" class="section dispatch" v-observe-visibility="onVisibilityChange">
      <div class="dispatch-content">
        <h2>{{ $t('solutionInfo.dispatch.title') }}</h2>
        <p>
          <strong>{{ $t('solutionInfo.overview') }}</strong><br>{{ $t('solutionInfo.dispatch.description') }}
        </p>
        <p>
          <strong>{{ $t('solutionInfo.services') }}</strong><br>{{ $t('solutionInfo.dispatch.services') }}
        </p>
        <img :src="preloadedImages.city5" alt="Company Profile" class="img" v-if="preloadedImages.city5">
      </div>
    </div>
  </div>
</template>

<script>
import { openDB } from 'idb';

const dbPromise = openDB('images-db', 1, {
  upgrade(db) {
    db.createObjectStore('images');
  },
});

async function loadImage(name) {
  const db = await dbPromise;
  const blob = await db.get('images', name);
  if (!blob) return null;
  return URL.createObjectURL(blob);
}

export default {
  name: 'SolutionInfo',
  data() {
    return {
      activeIcon: null,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    preloadedImages: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    navigateToSection(sectionId) {
      location.hash = `#${sectionId}`;
    },
    onVisibilityChange(isVisible, entry) {
      if (isVisible) {
        entry.target.classList.add('visible');
        if (entry.target.id) {
          const paragraphs = entry.target.querySelectorAll('p');
          paragraphs.forEach((p, index) => {
            p.classList.remove('fade-in-visible');
            setTimeout(() => {
              p.classList.add('fade-in-visible');
            }, index * 150); // Adjust the delay time as needed
          });
        }
      } else {
        entry.target.classList.remove('visible');
        if (entry.target.id) {
          const paragraphs = entry.target.querySelectorAll('p');
          paragraphs.forEach((p) => {
            p.classList.remove('fade-in-visible');
          });
        }
      }
    },
    setActiveIcon(index) {
      this.activeIcon = index;
    }
  },
  directives: {
    'observe-visibility': {
      inserted(el, binding) {
        const options = {
          threshold: 0.1 // Adjust as needed
        };
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (binding.value && typeof binding.value === 'function') {
              binding.value(entry.isIntersecting, entry);
            }
          });
        }, options);
        observer.observe(el);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.solution-info {
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;

  .banner {
    position: relative;
    width: 100%;
    height: 420px;
    overflow: hidden;
    margin-bottom: 40px;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .banner-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      
      h1 {
        font-size: 40px;
        letter-spacing: 2px;
        font-weight: 400;
        font-family: 'Noto Sans JP', sans-serif;
      }
    }
  }

  .section {
    width: 50.5%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    padding-top: 30px;  /* 添加顶部间距 */
    margin-top: -30px;
    margin-bottom: 14vh;
    font-family: 'Noto Sans JP', sans-serif;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    h2 {
      font-size: 40px;
      font-weight: 400;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 30px;
      color: #414c64;
    }

    p {
      text-align: left;
      margin-bottom: 30px;
      font-size: 15px;
      color: #414c64;
      line-height: 28px;

      &.fade-in-visible {
        opacity: 1;
        transform: translateY(0);
      }

      strong {
        color: #293244;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        line-height: 34px;
      }

      .fields {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .field {
        display: table;
        width: 100%;
        margin-bottom: 10px;

        .field-label, .field-content {
          display: table-cell;
          vertical-align: top;
          padding: 4px 0; /* 调整内边距以控制行高 */
        }

        .field-label {
          width: 10em; /* 固定宽度 */
          font-size: 15px;
          font-weight: 600;
        }

        .field-content {
          font-size: 15px;
        }
      }
    }
  }

  .img {
    width: 80%;
  }

  @media (max-width: 768px) {

    .banner {
      height: 320px; // 高度调整以适应移动端

      .banner-text {
        h1 {
          font-size: 24px; // 字体大小调整以适应移动端
        }
      }
    }

    .section {
      width: 88%; // 宽度调整以适应移动端
      padding-top: 15px;  // 添加顶部间距
      margin-top: -15px;
      margin-bottom: 7vh; // 间距调整以适应移动端

      h2 {
        font-size: 24px; // 字体大小调整以适应移动端
        margin-top: 10px;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 15px;
        font-size: 14px; // 字体大小调整以适应移动端
        line-height: 20px; // 行高调整以适应移动端

        strong {
          font-size: 16px; // 字体大小调整以适应移动端
          line-height: 30px;
        }

        .field {
          .field-label {
            width: 8em; /* 调整宽度以适应移动端 */
            font-size: 14px;
          }

          .field-content {
            font-size: 14px;
          }
        }
      }
    }

    .img {
      width: 100%; // 宽度调整以适应移动端
      max-width: 100%;
      height: auto; // 确保图片自适应高度
    }
  }
}
</style>
