import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  ja: {
    // HomePage
    bannerTitle: 'お互いに尊重しながら、幸福な生活に向けて成長して行こう',
    bannerSubtitle: 'Let\'s respect each other and strive towards a happy life',
    ourServices: '事業',
    pickUp: 'ピックアップ',
    // Navigation Buttons
    greeting: 'GREETING<br>代表挨拶',
    vision: 'VISION<br>経営理念・ビジョン',
    companyProfile: 'COMPANY PROFILE<br>会社概要',
    welfare: 'WELFARE<br>福利厚生',
    // NavBar
    achievementsNavBar: '開発実績',
    companyNavBar: 'すべての会社情報',
    visionNavBar: '経営理念・ビジョン',
    companyProfileNavBar: '会社概要',
    welfareNavBar: '福利厚生',
    solution: 'すべての事業内容',
    ses: 'SES事業',
    appDev: 'アプリ開発',
    dispatch: 'その他業種への人材支援',
    // Footer
    companyNameFooter: '株式会社<br />GrowthSoft',
    address: '〒102-0072<br />東京都千代田区飯田橋1-7-10<br />山京ビル本館7F',
    phone: '電話: 03-6265-4936',
    email: 'メール: info@growthsoft.co.jp',
    links: 'リンク',
    home: 'ホーム',
    companyInfo: '会社情報',
    recruitmentInfo: '採用情報',
    contact: 'お問い合わせ',
    copyright: 'Copyright © 2024. 株式会社GrowthSoft. All rights reserved.',
    // Greeting Section
    greetingTitle: 'ごあいさつ',
    greetingMission: '<strong>使命と目標</strong><br>私たちグロースソフトは、お客様のビジネス成功を最優先に考え、革新的なITソリューションを提供することを使命としています。幅広い業界で培った経験と専門知識を活かし、最適なソリューションを提供するために全力を尽くしています。',
    greetingService: '<strong>サービスの柔軟性と信頼関係</strong><br>お客様のニーズに応じた柔軟で効果的なサービスを提供し、常にお客様の期待を超える成果を実現することを目指しています。先進技術の導入と持続的な改善を通じて、信頼関係を築くことを大切にしています。',
    greetingEmployee: '<strong>社員の成長と貢献</strong><br>私たちの成功は、社員一人ひとりの成長と貢献によって支えられています。社員が自身の能力を最大限に発揮できる環境を整え、共に成長し続けることで、組織全体の発展を目指しています。尊重と協力の精神を持ち、チーム一丸となってお客様の成功に寄与します。',
    greetingFuture: '<strong>未来への挑戦と価値創造</strong><br>技術の進歩が日々進む中で、私たちは絶えず挑戦し、新たな価値を創造することに努めています。お客様と共に歩み、新しい未来を切り拓いていくことをお約束します。皆様からのご支援とご愛顧を賜りますよう、心よりお願い申し上げます。',
    ceoName: '王 強',
    // Vision Section
    visionTitle: '経営理念・ビジョン',
    visionMission: '<strong>使命と目標</strong><br>私たちの経営理念は、お客様、社員、そして社会全体に対して、お互いに尊重し合いながら成長し、幸福な生活を実現することです。私たちは常に高い倫理基準を持ち、信頼を築き上げることで、持続可能な社会の発展に貢献します。',
    visionService: '<strong>サービスの柔軟性と信頼関係</strong><br>お客様に対しては、優れた技術とサービスを提供し、その期待を超えるソリューションを提案します。社員に対しては、成長の機会と働きやすい環境を提供し、それぞれのキャリアパスを尊重します。社会に対しては、環境保護と地域社会への貢献を重視し、持続可能なビジネスを展開します。',
    visionEmployee: '<strong>社員の成長と貢献</strong><br>私たちは、お互いに尊重し合いながら共に成長し、幸福な生活に向けて努力を惜しみません。このビジョンを実現するために、私たちは日々の業務において誠実さと情熱を持って取り組んでまいります。',
    // Company Profile Section
    companyProfileTitle: '会社概要',
    companyNameLabel: '会社名',
    companyName: '株式会社GrowthSoft（グロースソフト）',
    capitalLabel: '資本金',
    capital: '5,000,000円',
    ceoLabel: '代表者',
    foundedLabel: '設立',
    founded: '2023年9月4日',
    employeesLabel: '従業員数',
    employees: '10名',
    headquartersLabel: '本社',
    headquarters: '〒102-0072 東京都千代田区飯田橋1丁目7番10号 山京ビル本館7階',
    phoneLabel: '電話',
    phone: '03-6265-4936',
    invoiceLabel: '適格請求書<br>発行事業者',
    invoiceNumber: 'T8013301051315<br>令和6年6月1日',
    // Welfare Section
    welfareTitle: '福利厚生',
    insurance: '保険',
    insuranceDetails: '健康保険、厚生年金、雇用保険、労災保険、子ども・子育て拠出金',
    vacation: '休暇',
    vacationDetails: '産休、育児休業、介護休業制度、フレックスタイム制度、短時間勤務制度（妊娠・育児・介護）',
    allowances: '手当',
    allowancesDetails: '通勤手当、精勤手当、時間外手当、住宅手当、年末年始特別出勤手当、資格取得補助、セミナーの費用補助',
    // Other
    home: 'ホーム',
    solutions: '事業内容',
    contact: 'お問い合わせ',
    language: 'Language',
    languages: {
      ja: '日本語',
      zh: '中文',
      en: 'English',
      ko: '한국어',
      vi: 'Tiếng Việt',
    },
    recruitment: {
      bannerTitle: '技術の力で価値創造に挑戦する<br>メンバーを募集しています',
      bannerButton: '詳細を見る',
      title: '採用情報',
      description: 'グロースソフトでは、下記の職種でエントリーを受け付けています。<br>募集内容の詳細については職種名をクリックしてご覧ください。',
      applyButton: '応募する',
      newGraduate: '新卒採用',
      careerRecruitment: '中途採用',
      job1: {
        title: '2025新卒採用（第二新卒）',
        details: [
          {
            title: '応募条件',
            content: [
              {
                label: '必須条件',
                value: `
                  <li>大学以上の学歴（専門学校の場合はIT関連専攻）</li>
                  <li>良好な性格とコミュニケーション能力</li>
                  <li>チームで協力して仕事ができる能力</li>
                `
              }
            ]
          },
          {
            title: '待遇',
            content: [
              {
                label: '想定年収',
                value: '300万円～400万円<br>※能力により優遇'
              },
              {
                label: '',
                value: `
                  <li>諸条件を考慮の上、当社規定によって決定</li>
                  <li>試用期間: あり (3ヶ月、労働条件の変更なし)</li>
                  <li>昇給: 年1回</li>
                  <li>賞与: 年2回 (決算賞与を支給する場合あり)</li>
                `
              }
            ]
          },
          {
            title: '勤務地',
            content: '東京/神奈川/千葉/埼玉'
          }
        ]
      },
      job2: {
        title: 'システムエンジニア',
        details: [
          {
            title: '職務内容',
            content: '当社のお客さま（事業会社、メーカー系SIer、ユーザー系SIer等）の開発現場で、システムエンジニア（要件定義、基本設計、詳細設計、開発、テスト、保守）としてシステム開発業務に従事していただきます。'
          },
          {
            title: '応募条件',
            content: [
              {
                label: '必須条件',
                value: `
                  <li>システム開発の経験を1年以上お持ちの方</li>
                  <li>プログラミング経験があり、Java、Python、C++などの言語に精通している方</li>
                `
              },
              {
                label: '望ましい経験・スキル',
                value: `
                  <li>過去にシステムの運用保守の経験があり、直近開発の経験をお持ちの方</li>
                  <li>上流工程でのPM、PLの経験や新人教育の経験者</li>
                  <li>Webアプリケーションやモバイルアプリケーションの開発経験者</li>
                  <li>データベース設計・管理の経験者</li>
                `
              }
            ]
          },
          {
            title: '待遇',
            content: [
              {
                label: '想定年収',
                value: '500万円～1000万円<br>※経験により優遇'
              },
              {
                label: '',
                value: `
                  <li>能力・経験・年齢等を考慮の上、当社規定によって決定</li>
                  <li>試用期間: あり (3ヶ月、労働条件の変更なし)</li>
                  <li>昇給: 年1回</li>
                  <li>賞与: 年2回 (決算賞与を支給する場合あり)</li>
                `
              }
            ]
          },
          {
            title: '勤務地',
            content: '東京/神奈川/千葉/埼玉'
          }
        ]
      },
      job3: {
        title: 'その他業種への人材支援',
        details: [
          {
            title: '応募条件',
            content: [
              {
                label: '必須条件',
                value: `
                  <li>良好な性格と強いコミュニケーション能力</li>
                  <li>チームで協力して仕事ができる能力</li>
                  <li>迅速かつ正確に業務を遂行できる能力</li>
                  <li>新しい環境に適応できる柔軟性</li>
                `
              }
            ]
          },
          {
            title: '待遇',
            content: [
              {
                label: '想定年収',
                value: '280万円～450万円<br>※経験により優遇'
              },
              {
                label: '',
                value: `
                  <li>能力・経験・年齢等を考慮の上、当社規定によって決定</li>
                  <li>試用期間: あり (3ヶ月、労働条件の変更なし)</li>
                  <li>昇給: 年1回</li>
                  <li>賞与: 年2回 (決算賞与を支給する場合あり)</li>
                `
              }
            ]
          },
          {
            title: '勤務地',
            content: '東京/神奈川/千葉/埼玉'
          }
        ]
      }
    },
    contactUs: {
      title: 'お問い合わせ',
      inquiryType: 'お問い合わせ種別',
      name: 'お名前',
      lastName: '姓',
      firstName: '名',
      companyName: '会社名・団体名',
      departmentName: '部署名',
      email: 'メールアドレス',
      phone: '電話番号',
      message: 'お問い合わせ内容',
      submitButton: '送信',
      required: '必須',
      optional: '任意',
      select: '選択してください',
      newGraduate: '新卒採用',
      careerRecruitment: '中途採用',
      serviceInquiry: 'サービス・企業に関するお問い合わせ',
      other: 'その他',
      validationMessage: 'このフィールドを入力してください。',
      successMessage: 'メッセージが送信されました！',
      errorMessage: 'メッセージの送信に失敗しました。もう一度お試しください。'
    },
    solutionInfo: {
      title: '事業内容',
      overview: '概要',
      technicalFields: '技術分野',
      services: 'サービス内容',
      ses: {
        title: 'SES事業',
        description: '当社では、SES（システムエンジニアリングサービス）事業を軸に、システム開発から運用・保守まで、一貫したサービスを提供しています。当社にはプロジェクトの開発段階から移行、そして保守・運用に至るまで、幅広いニーズに対応可能です。また、当社には優秀な外国籍エンジニアが多数在籍しており、質が高くコストパフォーマンスの良いサービスを提供しています。お見積もお気軽にご相談ください。',
        frontend: 'フロントエンド',
        languages: '開発言語',
        database: 'データベース',
        frontendContent: 'Vue.js、React.js、Angular.js等',
        languagesContent: 'Java、C、C++、C#、JavaScript、TypeScript、VB.NET、ASP.NET、COBOL、PHP等',
        databaseContent: 'Oracle、MySQL、PostgreSQL、SQLserver、MongoDB等'
      },
      appDev: {
        title: 'アプリ開発',
        description: 'アプリ開発事業では、スマートフォンアプリやウェブアプリの企画・開発・運用を行っています。最新技術を駆使し、ユーザーにとって使いやすいアプリを提供します。',
        services: '要件定義、デザイン、開発、テスト、リリースまで、一貫したサポートを提供します。お客様のビジネスモデルに最適なアプリケーションを開発し、競争力を高めます。'
      },
      dispatch: {
        title: 'その他業種への人材支援サービス',
        description: 'その他業種への人材支援サービスは、多様な業種の企業に対して、必要なスキルと経験を持つ優秀な人材を提供します。私たちはお客様のビジネスニーズに合わせて、迅速かつ確実に最適な人材をマッチングします。業種例として、ホテル、工場、飲食店などがあります。',
        services: '人材の募集・選考から勤務開始後のフォローアップまで、一貫したサービスを提供します。お客様のビジネスニーズに基づき、適切な人材を提供することで、お客様のビジネス成功をサポートします。'
      }
    },
  },
  zh: {
    // 首页
    bannerTitle: '在相互尊重中，一起向幸福生活成长',
    bannerSubtitle: 'Let\'s respect each other and strive towards a happy life',
    ourServices: '业务',
    pickUp: '推荐',
    // 导航按钮
    greeting: '致辞<br>代表致辞',
    vision: '愿景<br>经营理念与愿景',
    companyProfile: '公司简介<br>公司概况',
    welfare: '福利<br>福利待遇',
    // 导航栏
    achievementsNavBar: '开发实绩',
    companyNavBar: '所有公司信息',
    visionNavBar: '经营理念与愿景',
    companyProfileNavBar: '公司概况',
    welfareNavBar: '福利待遇',
    solution: '所有业务内容',
    ses: 'SES业务',
    appDev: '应用开发',
    dispatch: '其他行业的人才支援',
    // 页脚
    companyNameFooter: '株式会社<br />GrowthSoft',
    address: '〒102-0072<br />东京都千代田区饭田桥1-7-10<br />山京大厦本馆7F',
    phone: '电话: 03-6265-4936',
    email: '邮箱: info@growthsoft.co.jp',
    links: '链接',
    home: '首页',
    companyInfo: '公司信息',
    recruitmentInfo: '招聘信息',
    contact: '联系我们',
    copyright: '版权 © 2024. 株式会社GrowthSoft. 保留所有权利。',
    // 致辞部分
    greetingTitle: '致辞',
    greetingMission: '<strong>使命与目标</strong><br>我们GrowthSoft以客户的商业成功为首要目标，致力于提供创新的IT解决方案。我们利用在各个行业积累的经验和专业知识，全力提供最佳解决方案。',
    greetingService: '<strong>服务的灵活性与信任关系</strong><br>我们提供灵活且高效的服务，满足客户需求，力求始终超越客户期望。通过引入先进技术和持续改进，我们重视建立信任关系。',
    greetingEmployee: '<strong>员工的成长与贡献</strong><br>我们的成功依靠每位员工的成长和贡献。我们提供一个让员工充分发挥能力的环境，通过共同成长，推动整个组织的发展。我们尊重和合作，为客户的成功贡献力量。',
    greetingFuture: '<strong>对未来的挑战与价值创造</strong><br>在技术日新月异的进步中，我们不断挑战自我，致力于创造新价值。我们承诺与客户共同前行，开拓新的未来。真诚希望得到大家的支持和厚爱。',
    ceoName: '王 强',
    // 愿景部分
    visionTitle: '经营理念与愿景',
    visionMission: '<strong>使命与目标</strong><br>我们的经营理念是，在相互尊重的基础上，与客户、员工和整个社会共同成长，实现幸福生活。我们始终保持高标准的道德规范，通过建立信任，为可持续发展的社会做出贡献。',
    visionService: '<strong>服务的灵活性与信任关系</strong><br>我们为客户提供卓越的技术和服务，提出超越其期望的解决方案。为员工提供成长机会和舒适的工作环境，尊重每个人的职业发展。重视环境保护和对社区的贡献，开展可持续的业务。',
    visionEmployee: '<strong>员工的成长与贡献</strong><br>我们在相互尊重中共同成长，努力追求幸福生活。为实现这一愿景，我们在日常工作中始终以诚实和热情为动力。',
    // 公司概况部分
    companyProfileTitle: '公司概况',
    companyNameLabel: '公司名称',
    companyName: '株式会社GrowthSoft（GrowthSoft）',
    capitalLabel: '注册资本',
    capital: '5,000,000日元',
    ceoLabel: '代表人',
    foundedLabel: '成立时间',
    founded: '2023年9月4日',
    employeesLabel: '员工人数',
    employees: '10人',
    headquartersLabel: '总部',
    headquarters: '〒102-0072 东京都千代田区饭田桥1-7-10 山京大厦本馆7层',
    phoneLabel: '电话',
    phone: '03-6265-4936',
    invoiceLabel: '合格发票<br>发行企业',
    invoiceNumber: 'T8013301051315<br>令和6年6月1日',
    // 福利待遇部分
    welfareTitle: '福利待遇',
    insurance: '保险',
    insuranceDetails: '健康保险、厚生年金、雇佣保险、工伤保险、儿童育儿补助金',
    vacation: '休假',
    vacationDetails: '产假、育儿假、护理假、弹性工作制、短时间工作制（怀孕、育儿、护理）',
    allowances: '补贴',
    allowancesDetails: '通勤补贴、勤勉补贴、加班补贴、住房补贴、年终特别出勤补贴、资格考试补助、研讨会费用补助',
    // 其他
    home: '首页',
    solutions: '业务内容',
    contact: '联系我们',
    language: '语言',
    languages: {
      ja: '日本語',
      zh: '中文',
      en: 'English',
      ko: '한국어',
      vi: 'Tiếng Việt',
    },
    recruitment: {
      bannerTitle: '招募挑战通过技术创造价值的成员',
      bannerButton: '查看详情',
      title: '招聘信息',
      description: 'GrowthSoft正在接受以下职位的申请。<br>点击职位名称查看详细信息。',
      applyButton: '申请',
      newGraduate: '新卒招聘',
      careerRecruitment: '中途招聘',
      job1: {
        title: '2025新卒招聘（第二新卒）',
        details: [
          {
            title: '申请条件',
            content: [
              {
                label: '必要条件',
                value: `
                  <li>大学以上学历（专科学校需为IT相关专业）</li>
                  <li>良好的性格和沟通能力</li>
                  <li>能够团队合作工作</li>
                `
              }
            ]
          },
          {
            title: '待遇',
            content: [
              {
                label: '预期年收入',
                value: '300万日元～400万日元<br>※根据能力优待'
              },
              {
                label: '',
                value: `
                  <li>根据公司规定，考虑各种条件后决定</li>
                  <li>试用期：有 (3个月，无劳动条件变化)</li>
                  <li>晋升：每年一次</li>
                  <li>奖金：每年两次（可能有决算奖金）</li>
                `
              }
            ]
          },
          {
            title: '工作地点',
            content: '东京/神奈川/千叶/埼玉'
          }
        ]
      },
      job2: {
        title: '系统工程师',
        details: [
          {
            title: '职位内容',
            content: '在本公司客户（事业公司、制造商系SIer、用户系SIer等）的开发现场，作为系统工程师（需求定义、基本设计、详细设计、开发、测试、维护）从事系统开发工作。'
          },
          {
            title: '申请条件',
            content: [
              {
                label: '必要条件',
                value: `
                  <li>拥有1年以上系统开发经验</li>
                  <li>有编程经验，精通Java、Python、C++等语言</li>
                `
              },
              {
                label: '理想经验和技能',
                value: `
                  <li>过去有系统运维经验，近期有开发经验者</li>
                  <li>有上游工程PM、PL经验或新人培训经验者</li>
                  <li>有Web应用程序或移动应用程序开发经验者</li>
                  <li>有数据库设计和管理经验者</li>
                `
              }
            ]
          },
          {
            title: '待遇',
            content: [
              {
                label: '预期年收入',
                value: '500万日元～1000万日元<br>※根据经验优待'
              },
              {
                label: '',
                value: `
                  <li>根据公司规定，考虑能力、经验、年龄等决定</li>
                  <li>试用期：有 (3个月，无劳动条件变化)</li>
                  <li>晋升：每年一次</li>
                  <li>奖金：每年两次（可能有决算奖金）</li>
                `
              }
            ]
          },
          {
            title: '工作地点',
            content: '东京/神奈川/千叶/埼玉'
          }
        ]
      },
      job3: {
        title: '其他行业的人才支援',
        details: [
          {
            title: '申请条件',
            content: [
              {
                label: '必要条件',
                value: `
                  <li>良好的性格和强大的沟通能力</li>
                  <li>能够团队合作工作</li>
                  <li>能够迅速且准确地完成工作</li>
                  <li>能够适应新环境的灵活性</li>
                `
              }
            ]
          },
          {
            title: '待遇',
            content: [
              {
                label: '预期年收入',
                value: '280万日元～450万日元<br>※根据经验优待'
              },
              {
                label: '',
                value: `
                  <li>根据公司规定，考虑能力、经验、年龄等决定</li>
                  <li>试用期：有 (3个月，无劳动条件变化)</li>
                  <li>晋升：每年一次</li>
                  <li>奖金：每年两次（可能有决算奖金）</li>
                `
              }
            ]
          },
          {
            title: '工作地点',
            content: '东京/神奈川/千叶/埼玉'
          }
        ]
      }
    },
    contactUs: {
      title: '联系我们',
      inquiryType: '咨询类别',
      name: '姓名',
      lastName: '姓',
      firstName: '名',
      companyName: '公司名或团体名',
      departmentName: '部门名',
      email: '电子邮箱',
      phone: '电话号码',
      message: '咨询内容',
      submitButton: '发送',
      required: '必填',
      optional: '选填',
      select: '请选择',
      newGraduate: '新卒招聘',
      careerRecruitment: '中途招聘',
      serviceInquiry: '服务或公司相关咨询',
      other: '其他',
      validationMessage: '请填写此字段。',
      successMessage: '消息已发送！',
      errorMessage: '消息发送失败，请重试。'
    },
    solutionInfo: {
      title: '业务内容',
      overview: '概述',
      technicalFields: '技术领域',
      services: '服务内容',
      ses: {
        title: 'SES业务',
        description: '我们以SES（系统工程服务）业务为主，从系统开发到运维和维护，提供一站式服务。我们能够应对从项目开发阶段到迁移、维护和运维的广泛需求。此外，我们拥有许多优秀的外国工程师，提供高质量且高性价比的服务。欢迎随时咨询报价。',
        frontend: '前端',
        languages: '开发语言',
        database: '数据库',
        frontendContent: 'Vue.js、React.js、Angular.js等',
        languagesContent: 'Java、C、C++、C#、JavaScript、TypeScript、VB.NET、ASP.NET、COBOL、PHP等',
        databaseContent: 'Oracle、MySQL、PostgreSQL、SQLserver、MongoDB等'
      },
      appDev: {
        title: '应用开发',
        description: '在应用开发业务中，我们从事智能手机应用和网络应用的策划、开发和运维。我们利用最新技术，提供用户友好的应用程序。',
        services: '从需求定义、设计、开发、测试到发布，我们提供一站式支持。我们为客户开发最适合其商业模式的应用，提高竞争力。'
      },
      dispatch: {
        title: '其他行业的人才支援服务',
        description: '其他行业的人才支援服务为各种行业的企业提供具备必要技能和经验的优秀人才。我们根据客户的商业需求，快速且准确地匹配最佳人才。行业例子包括酒店、工厂、餐饮店等。',
        services: '从人才招聘、筛选到入职后的跟进，我们提供一站式服务。我们根据客户的商业需求，提供适当的人才，支持客户的商业成功。'
      }
    }
  },
  en: {
    // HomePage
    bannerTitle: '',
    bannerSubtitle: 'Let\'s respect each other and strive towards a happy life',
    ourServices: 'Services',
    pickUp: 'Pick Up',
    // Navigation Buttons
    greeting: 'GREETING<br>CEO Greeting',
    vision: 'VISION<br>Management Philosophy & Vision',
    companyProfile: 'COMPANY PROFILE<br>Company Overview',
    welfare: 'WELFARE<br>Welfare',
    // NavBar
    achievementsNavBar: 'Achievements',
    companyNavBar: 'All Company Information',
    visionNavBar: 'Management Philosophy & Vision',
    companyProfileNavBar: 'Company Overview',
    welfareNavBar: 'Welfare',
    solution: 'All Business Contents',
    ses: 'SES Business',
    appDev: 'App Development',
    dispatch: 'Other Industry Staffing Support',
    // Footer
    companyNameFooter: 'GrowthSoft Inc.',
    address: '1-7-10 Iidabashi, Chiyoda-ku, Tokyo 102-0072<br />Yamakyu Building Main Building 7F',
    phone: 'Phone: 03-6265-4936',
    email: 'Email: info@growthsoft.co.jp',
    links: 'Links',
    home: 'Home',
    companyInfo: 'Company Information',
    recruitmentInfo: 'Recruitment',
    contact: 'Contact Us',
    copyright: 'Copyright © 2024. GrowthSoft Inc. All rights reserved.',
    // Greeting Section
    greetingTitle: 'Greeting',
    greetingMission: '<strong>Mission and Goals</strong><br>At GrowthSoft, our top priority is the success of our clients\' businesses, and we are dedicated to providing innovative IT solutions. Utilizing our extensive experience and expertise across various industries, we strive to offer the best solutions.',
    greetingService: '<strong>Service Flexibility and Trust</strong><br>We aim to provide flexible and effective services tailored to the needs of our clients, consistently exceeding their expectations. We value building trust through the introduction of advanced technologies and continuous improvement.',
    greetingEmployee: '<strong>Employee Growth and Contribution</strong><br>Our success is supported by the growth and contribution of each employee. We create an environment where employees can fully utilize their abilities and continue to grow together, aiming for the development of the entire organization. With a spirit of respect and cooperation, we contribute to the success of our clients.',
    greetingFuture: '<strong>Challenges and Value Creation for the Future</strong><br>In a world where technology advances daily, we constantly challenge ourselves and strive to create new value. We promise to walk alongside our clients and open up new futures together. We sincerely ask for your continued support and patronage.',
    ceoName: 'Wang Qiang',
    // Vision Section
    visionTitle: 'Management Philosophy & Vision',
    visionMission: '<strong>Mission and Goals</strong><br>Our management philosophy is to grow and achieve a happy life by respecting each other with our clients, employees, and society as a whole. We always adhere to high ethical standards and contribute to the development of a sustainable society by building trust.',
    visionService: '<strong>Service Flexibility and Trust</strong><br>We provide superior technology and services to our clients, proposing solutions that exceed their expectations. We offer growth opportunities and a comfortable working environment for employees, respecting each individual\'s career path. We focus on environmental protection and community contributions, conducting sustainable business.',
    visionEmployee: '<strong>Employee Growth and Contribution</strong><br>We strive for a happy life while growing together with mutual respect. To achieve this vision, we approach our daily work with sincerity and passion.',
    // Company Profile Section
    companyProfileTitle: 'Company Overview',
    companyNameLabel: 'Company Name',
    companyName: 'GrowthSoft Inc.',
    capitalLabel: 'Capital',
    capital: '5,000,000 JPY',
    ceoLabel: 'Representative',
    foundedLabel: 'Established',
    founded: 'September 4, 2023',
    employeesLabel: 'Number of Employees',
    employees: '10',
    headquartersLabel: 'Headquarters',
    headquarters: '1-7-10 Iidabashi, Chiyoda-ku, Tokyo 102-0072, Yamakyu Building Main Building 7F',
    phoneLabel: 'Phone',
    phone: '03-6265-4936',
    invoiceLabel: 'Qualified Invoice<br>Issuing Business',
    invoiceNumber: 'T8013301051315<br>From June 1, 2024',
    // Welfare Section
    welfareTitle: 'Welfare',
    insurance: 'Insurance',
    insuranceDetails: 'Health Insurance, Welfare Pension, Employment Insurance, Workers\' Accident Compensation Insurance, Childcare Contribution',
    vacation: 'Vacation',
    vacationDetails: 'Maternity Leave, Childcare Leave, Care Leave System, Flextime System, Short-time Work System (Pregnancy, Childcare, Nursing Care)',
    allowances: 'Allowances',
    allowancesDetails: 'Commuting Allowance, Attendance Allowance, Overtime Allowance, Housing Allowance, Year-end Special Duty Allowance, Qualification Acquisition Support, Seminar Fee Support',
    // Other
    home: 'Home',
    solutions: 'Services',
    contact: 'Contact Us',
    language: 'Language',
    languages: {
      ja: '日本語',
      zh: '中文',
      en: 'English',
      ko: '한국어',
      vi: 'Tiếng Việt',
    },
    recruitment: {
      bannerTitle: 'We are recruiting members who challenge value creation with the power of technology',
      bannerButton: 'See Details',
      title: 'Recruitment Information',
      description: 'GrowthSoft is accepting entries for the following positions.<br>Click on the job title to view the details.',
      applyButton: 'Apply',
      newGraduate: 'New Graduate Recruitment',
      careerRecruitment: 'Mid-career Recruitment',
      job1: {
        title: '2025 New Graduate Recruitment (Second Graduate)',
        details: [
          {
            title: 'Application Conditions',
            content: [
              {
                label: 'Required Conditions',
                value: `
                  <li>University degree or higher (IT-related major for technical college graduates)</li>
                  <li>Good personality and communication skills</li>
                  <li>Ability to work cooperatively in a team</li>
                `
              }
            ]
          },
          {
            title: 'Treatment',
            content: [
              {
                label: 'Expected Annual Income',
                value: '3 million to 4 million JPY<br>※Preferential treatment according to ability'
              },
              {
                label: '',
                value: `
                  <li>Determined according to company regulations, considering various conditions</li>
                  <li>Probation period: Yes (3 months, no change in working conditions)</li>
                  <li>Salary increase: Once a year</li>
                  <li>Bonus: Twice a year (may provide settlement bonus)</li>
                `
              }
            ]
          },
          {
            title: 'Work Location',
            content: 'Tokyo/Kanagawa/Chiba/Saitama'
          }
        ]
      },
      job2: {
        title: 'System Engineer',
        details: [
          {
            title: 'Job Description',
            content: 'You will be engaged in system development work as a system engineer (requirements definition, basic design, detailed design, development, testing, maintenance) in the development sites of our customers (business companies, manufacturer SIers, user SIers, etc.).'
          },
          {
            title: 'Application Conditions',
            content: [
              {
                label: 'Required Conditions',
                value: `
                  <li>At least 1 year of system development experience</li>
                  <li>Programming experience and proficiency in languages such as Java, Python, C++, etc.</li>
                `
              },
              {
                label: 'Desirable Experience and Skills',
                value: `
                  <li>Experience in system operation and maintenance in the past, with recent development experience</li>
                  <li>Experience in PM, PL in upstream processes, or experience in training new employees</li>
                  <li>Experience in developing web applications or mobile applications</li>
                  <li>Experience in database design and management</li>
                `
              }
            ]
          },
          {
            title: 'Treatment',
            content: [
              {
                label: 'Expected Annual Income',
                value: '5 million to 10 million JPY<br>※Preferential treatment according to experience'
              },
              {
                label: '',
                value: `
                  <li>Determined according to company regulations, considering ability, experience, age, etc.</li>
                  <li>Probation period: Yes (3 months, no change in working conditions)</li>
                  <li>Salary increase: Once a year</li>
                  <li>Bonus: Twice a year (may provide settlement bonus)</li>
                `
              }
            ]
          },
          {
            title: 'Work Location',
            content: 'Tokyo/Kanagawa/Chiba/Saitama'
          }
        ]
      },
      job3: {
        title: 'Staffing Support for Other Industries',
        details: [
          {
            title: 'Application Conditions',
            content: [
              {
                label: 'Required Conditions',
                value: `
                  <li>Good personality and strong communication skills</li>
                  <li>Ability to work cooperatively in a team</li>
                  <li>Ability to perform tasks quickly and accurately</li>
                  <li>Flexibility to adapt to new environments</li>
                `
              }
            ]
          },
          {
            title: 'Treatment',
            content: [
              {
                label: 'Expected Annual Income',
                value: '2.8 million to 4.5 million JPY<br>※Preferential treatment according to experience'
              },
              {
                label: '',
                value: `
                  <li>Determined according to company regulations, considering ability, experience, age, etc.</li>
                  <li>Probation period: Yes (3 months, no change in working conditions)</li>
                  <li>Salary increase: Once a year</li>
                  <li>Bonus: Twice a year (may provide settlement bonus)</li>
                `
              }
            ]
          },
          {
            title: 'Work Location',
            content: 'Tokyo/Kanagawa/Chiba/Saitama'
          }
        ]
      }
    },
    contactUs: {
      title: 'Contact Us',
      inquiryType: 'Inquiry Type',
      name: 'Name',
      lastName: 'Last Name',
      firstName: 'First Name',
      companyName: 'Company Name or Organization',
      departmentName: 'Department Name',
      email: 'Email Address',
      phone: 'Phone Number',
      message: 'Message',
      submitButton: 'Submit',
      required: 'Required',
      optional: 'Optional',
      select: 'Please Select',
      newGraduate: 'New Graduate Recruitment',
      careerRecruitment: 'Mid-career Recruitment',
      serviceInquiry: 'Service or Company Inquiry',
      other: 'Other',
      validationMessage: 'Please fill out this field.',
      successMessage: 'Message has been sent!',
      errorMessage: 'Message sending failed. Please try again.'
    },
    solutionInfo: {
      title: 'Services',
      overview: 'Overview',
      technicalFields: 'Technical Fields',
      services: 'Services',
      ses: {
        title: 'SES Business',
        description: 'We focus on SES (System Engineering Services) business, providing comprehensive services from system development to operation and maintenance. We can meet a wide range of needs, from the development phase of projects to migration, and maintenance and operation. Additionally, we have many excellent foreign engineers who provide high-quality and cost-effective services. Feel free to consult with us for a quote.',
        frontend: 'Frontend',
        languages: 'Development Languages',
        database: 'Database',
        frontendContent: 'Vue.js, React.js, Angular.js, etc.',
        languagesContent: 'Java, C, C++, C#, JavaScript, TypeScript, VB.NET, ASP.NET, COBOL, PHP, etc.',
        databaseContent: 'Oracle, MySQL, PostgreSQL, SQLserver, MongoDB, etc.'
      },
      appDev: {
        title: 'App Development',
        description: 'In the app development business, we plan, develop, and operate smartphone and web applications. We provide user-friendly applications utilizing the latest technology.',
        services: 'We offer comprehensive support from requirements definition, design, development, testing to release. We develop applications optimized for our clients\' business models, enhancing their competitiveness.'
      },
      dispatch: {
        title: 'Staffing Support for Other Industries',
        description: 'The staffing support service for other industries provides excellent personnel with the necessary skills and experience to various industry companies. We quickly and accurately match the best personnel according to the business needs of our clients. Examples of industries include hotels, factories, and restaurants.',
        services: 'We provide comprehensive services from recruitment and selection to follow-up after starting work. We support the business success of our clients by providing appropriate personnel based on their business needs.'
      }
    }
  },
  ko: {
    // HomePage
    bannerTitle: '서로를 존중하며 행복한 삶을 향해 성장해 나가자',
    bannerSubtitle: 'Let\'s respect each other and strive towards a happy life',
    ourServices: '사업',
    pickUp: '추천',
    // Navigation Buttons
    greeting: '인사말<br>대표 인사',
    vision: '비전<br>경영 철학 및 비전',
    companyProfile: '회사 개요<br>회사 프로필',
    welfare: '복지<br>복리 후생',
    // NavBar
    achievementsNavBar: '개발 실적',
    companyNavBar: '모든 회사 정보',
    visionNavBar: '경영 철학 및 비전',
    companyProfileNavBar: '회사 프로필',
    welfareNavBar: '복리 후생',
    solution: '모든 사업 내용',
    ses: 'SES 사업',
    appDev: '앱 개발',
    dispatch: '다른 산업의 인재 지원',
    // Footer
    companyNameFooter: '주식회사<br />GrowthSoft',
    address: '〒102-0072<br />도쿄도 치요다구 이이다바시 1-7-10<br />야마쿄 빌딩 본관 7F',
    phone: '전화: 03-6265-4936',
    email: '이메일: info@growthsoft.co.jp',
    links: '링크',
    home: '홈',
    companyInfo: '회사 정보',
    recruitmentInfo: '채용 정보',
    contact: '문의하기',
    copyright: '저작권 © 2024. 주식회사 GrowthSoft. 모든 권리 보유.',
    // Greeting Section
    greetingTitle: '인사말',
    greetingMission: '<strong>사명과 목표</strong><br>저희 GrowthSoft는 고객의 비즈니스 성공을 최우선으로 생각하며, 혁신적인 IT 솔루션을 제공하는 것을 사명으로 하고 있습니다. 다양한 산업에서 쌓은 경험과 전문 지식을 활용하여 최적의 솔루션을 제공하기 위해 최선을 다하고 있습니다.',
    greetingService: '<strong>서비스의 유연성과 신뢰 관계</strong><br>고객의 요구에 맞춘 유연하고 효과적인 서비스를 제공하며, 항상 고객의 기대를 뛰어넘는 성과를 실현하는 것을 목표로 하고 있습니다. 첨단 기술 도입과 지속적인 개선을 통해 신뢰 관계를 구축하는 것을 중요하게 생각합니다.',
    greetingEmployee: '<strong>직원의 성장과 기여</strong><br>저희의 성공은 직원 한 사람 한 사람의 성장과 기여에 의해 지탱되고 있습니다. 직원이 자신의 능력을 최대한 발휘할 수 있는 환경을 조성하고, 함께 성장함으로써 조직 전체의 발전을 목표로 하고 있습니다. 존중과 협력의 정신을 가지고 팀이 하나가 되어 고객의 성공에 기여합니다.',
    greetingFuture: '<strong>미래에 대한 도전과 가치 창조</strong><br>기술의 진보가 일상적으로 진행되는 가운데, 저희는 끊임없이 도전하고 새로운 가치를 창출하기 위해 노력하고 있습니다. 고객과 함께 새로운 미래를 개척해 나갈 것을 약속드립니다. 여러분의 지원과 사랑을 부탁드립니다.',
    ceoName: '왕 강',
    // Vision Section
    visionTitle: '경영 철학 및 비전',
    visionMission: '<strong>사명과 목표</strong><br>저희의 경영 철학은 고객, 직원, 그리고 사회 전체에 대해 서로 존중하며 성장하고 행복한 삶을 실현하는 것입니다. 저희는 항상 높은 윤리 기준을 가지고 신뢰를 구축함으로써 지속 가능한 사회 발전에 기여합니다.',
    visionService: '<strong>서비스의 유연성과 신뢰 관계</strong><br>고객에게는 뛰어난 기술과 서비스를 제공하며, 그 기대를 뛰어넘는 솔루션을 제안합니다. 직원에게는 성장 기회와 일하기 좋은 환경을 제공하며, 각자의 커리어 패스를 존중합니다. 사회에 대해서는 환경 보호와 지역 사회에의 공헌을 중시하며, 지속 가능한 비즈니스를 전개합니다.',
    visionEmployee: '<strong>직원의 성장과 기여</strong><br>저희는 서로 존중하며 함께 성장하고, 행복한 삶을 향해 노력합니다. 이 비전을 실현하기 위해 저희는 일상 업무에서 성실함과 열정을 가지고 임하고 있습니다.',
    // Company Profile Section
    companyProfileTitle: '회사 개요',
    companyNameLabel: '회사명',
    companyName: '주식회사 GrowthSoft（그로스소프트）',
    capitalLabel: '자본금',
    capital: '5,000,000엔',
    ceoLabel: '대표자',
    foundedLabel: '설립일',
    founded: '2023년 9월 4일',
    employeesLabel: '직원 수',
    employees: '10명',
    headquartersLabel: '본사',
    headquarters: '〒102-0072 도쿄도 치요다구 이이다바시 1-7-10 야마쿄 빌딩 본관 7층',
    phoneLabel: '전화',
    phone: '03-6265-4936',
    invoiceLabel: '적격 청구서<br>발행 사업자',
    invoiceNumber: 'T8013301051315<br>2024년 6월 1일부터',
    // Welfare Section
    welfareTitle: '복리 후생',
    insurance: '보험',
    insuranceDetails: '건강 보험, 후생 연금, 고용 보험, 산재 보험, 아동 및 육아 기여금',
    vacation: '휴가',
    vacationDetails: '산휴, 육아 휴직, 간병 휴직 제도, 유연 근무제, 단시간 근무 제도（임신, 육아, 간병）',
    allowances: '수당',
    allowancesDetails: '교통비, 성실 수당, 초과 근무 수당, 주택 수당, 연말 특별 출근 수당, 자격 취득 지원, 세미나 비용 지원',
    // Other
    home: '홈',
    solutions: '사업 내용',
    contact: '문의하기',
    language: '언어',
    languages: {
      ja: '日本語',
      zh: '中文',
      en: 'English',
      ko: '한국어',
      vi: 'Tiếng Việt',
    },
    recruitment: {
      bannerTitle: '기술의 힘으로 가치 창조에 도전할 멤버를 모집합니다',
      bannerButton: '자세히 보기',
      title: '채용 정보',
      description: 'GrowthSoft에서는 아래의 직종에서 지원을 받고 있습니다.<br>모집 내용의 자세한 사항은 직종명을 클릭하여 확인해 주세요.',
      applyButton: '지원하기',
      newGraduate: '신입 채용',
      careerRecruitment: '경력 채용',
      job1: {
        title: '2025 신입 채용（제2 신입）',
        details: [
          {
            title: '지원 조건',
            content: [
              {
                label: '필수 조건',
                value: `
                  <li>대학 이상의 학력（전문학교의 경우 IT 관련 전공）</li>
                  <li>좋은 성격과 커뮤니케이션 능력</li>
                  <li>팀으로 협력하여 일할 수 있는 능력</li>
                `
              }
            ]
          },
          {
            title: '대우',
            content: [
              {
                label: '예상 연봉',
                value: '300만 엔~400만 엔<br>※능력에 따라 우대'
              },
              {
                label: '',
                value: `
                  <li>여러 조건을 고려하여 회사 규정에 따라 결정</li>
                  <li>수습 기간: 있음 (3개월, 노동 조건의 변경 없음)</li>
                  <li>급여 인상: 연 1회</li>
                  <li>보너스: 연 2회 (결산 보너스를 지급할 경우 있음)</li>
                `
              }
            ]
          },
          {
            title: '근무지',
            content: '도쿄/가나가와/지바/사이타마'
          }
        ]
      },
      job2: {
        title: '시스템 엔지니어',
        details: [
          {
            title: '직무 내용',
            content: '저희 회사 고객（사업 회사, 제조업계 SIer, 사용자 계열 SIer 등）의 개발 현장에서 시스템 엔지니어（요건 정의, 기본 설계, 상세 설계, 개발, 테스트, 유지 보수）로서 시스템 개발 업무를 수행합니다.'
          },
          {
            title: '지원 조건',
            content: [
              {
                label: '필수 조건',
                value: `
                  <li>시스템 개발 경험 1년 이상 보유한 자</li>
                  <li>프로그래밍 경험이 있고 Java, Python, C++ 등의 언어에 능통한 자</li>
                `
              },
              {
                label: '바람직한 경험 및 기술',
                value: `
                  <li>과거에 시스템 운영 및 유지 보수 경험이 있으며 최근 개발 경험이 있는 자</li>
                  <li>상류 공정에서의 PM, PL 경험이나 신입 교육 경험자</li>
                  <li>웹 애플리케이션이나 모바일 애플리케이션 개발 경험자</li>
                  <li>데이터베이스 설계 및 관리 경험자</li>
                `
              }
            ]
          },
          {
            title: '대우',
            content: [
              {
                label: '예상 연봉',
                value: '500만 엔~1000만 엔<br>※경험에 따라 우대'
              },
              {
                label: '',
                value: `
                  <li>능력, 경험, 연령 등을 고려하여 회사 규정에 따라 결정</li>
                  <li>수습 기간: 있음 (3개월, 노동 조건의 변경 없음)</li>
                  <li>급여 인상: 연 1회</li>
                  <li>보너스: 연 2회 (결산 보너스를 지급할 경우 있음)</li>
                `
              }
            ]
          },
          {
            title: '근무지',
            content: '도쿄/가나가와/지바/사이타마'
          }
        ]
      },
      job3: {
        title: '다른 산업의 인재 지원',
        details: [
          {
            title: '지원 조건',
            content: [
              {
                label: '필수 조건',
                value: `
                  <li>좋은 성격과 강한 커뮤니케이션 능력</li>
                  <li>팀으로 협력하여 일할 수 있는 능력</li>
                  <li>신속하고 정확하게 업무를 수행할 수 있는 능력</li>
                  <li>새로운 환경에 적응할 수 있는 유연성</li>
                `
              }
            ]
          },
          {
            title: '대우',
            content: [
              {
                label: '예상 연봉',
                value: '280만 엔~450만 엔<br>※경험에 따라 우대'
              },
              {
                label: '',
                value: `
                  <li>능력, 경험, 연령 등을 고려하여 회사 규정에 따라 결정</li>
                  <li>수습 기간: 있음 (3개월, 노동 조건의 변경 없음)</li>
                  <li>급여 인상: 연 1회</li>
                  <li>보너스: 연 2회 (결산 보너스를 지급할 경우 있음)</li>
                `
              }
            ]
          },
          {
            title: '근무지',
            content: '도쿄/가나가와/지바/사이타마'
          }
        ]
      }
    },
    contactUs: {
      title: '문의하기',
      inquiryType: '문의 유형',
      name: '이름',
      lastName: '성',
      firstName: '이름',
      companyName: '회사명 또는 단체명',
      departmentName: '부서명',
      email: '이메일 주소',
      phone: '전화번호',
      message: '문의 내용',
      submitButton: '제출',
      required: '필수',
      optional: '선택',
      select: '선택해 주세요',
      newGraduate: '신입 채용',
      careerRecruitment: '경력 채용',
      serviceInquiry: '서비스 또는 회사 관련 문의',
      other: '기타',
      validationMessage: '이 필드를 작성해 주세요.',
      successMessage: '메시지가 전송되었습니다!',
      errorMessage: '메시지 전송에 실패했습니다. 다시 시도해 주세요.'
    },
    solutionInfo: {
      title: '사업 내용',
      overview: '개요',
      technicalFields: '기술 분야',
      services: '서비스 내용',
      ses: {
        title: 'SES 사업',
        description: '저희는 SES（시스템 엔지니어링 서비스） 사업을 중심으로, 시스템 개발부터 운영 및 유지 보수까지 일관된 서비스를 제공합니다. 프로젝트 개발 단계부터 이행, 유지 보수 및 운영에 이르기까지 다양한 요구에 대응 가능합니다. 또한, 우수한 외국인 엔지니어들이 다수 재직하고 있어, 고품질의 비용 효율적인 서비스를 제공합니다. 견적은 언제든지 상담해 주세요.',
        frontend: '프론트엔드',
        languages: '개발 언어',
        database: '데이터베이스',
        frontendContent: 'Vue.js、React.js、Angular.js 등',
        languagesContent: 'Java、C、C++、C#、JavaScript、TypeScript、VB.NET、ASP.NET、COBOL、PHP 등',
        databaseContent: 'Oracle、MySQL、PostgreSQL、SQLserver、MongoDB 등'
      },
      appDev: {
        title: '앱 개발',
        description: '앱 개발 사업에서는 스마트폰 앱과 웹 앱의 기획, 개발 및 운영을 수행합니다. 최신 기술을 활용하여 사용자에게 친숙한 앱을 제공합니다.',
        services: '요구 사항 정의, 디자인, 개발, 테스트, 출시까지 일관된 지원을 제공합니다. 고객의 비즈니스 모델에 최적화된 애플리케이션을 개발하여 경쟁력을 높입니다.'
      },
      dispatch: {
        title: '다른 산업의 인재 지원 서비스',
        description: '다른 산업의 인재 지원 서비스는 다양한 산업의 기업에 필요한 기술과 경험을 갖춘 우수한 인재를 제공합니다. 고객의 비즈니스 요구에 맞춰 신속하고 정확하게 최적의 인재를 매칭합니다. 산업 예로는 호텔, 공장, 음식점 등이 있습니다.',
        services: '인재 모집 및 선발부터 근무 시작 후의 팔로우업까지 일관된 서비스를 제공합니다. 고객의 비즈니스 요구에 맞춘 적절한 인재를 제공함으로써, 고객의 비즈니스 성공을 지원합니다.'
      }
    }
  },
  vi: {
    // HomePage
    bannerTitle: 'Hãy cùng nhau trưởng thành hướng tới cuộc sống hạnh phúc trong sự tôn trọng lẫn nhau',
    bannerSubtitle: 'Let\'s respect each other and strive towards a happy life',
    ourServices: 'Dịch vụ',
    pickUp: 'Đề xuất',
    // Navigation Buttons
    greeting: 'LỜI CHÀO<br>Lời chào của CEO',
    vision: 'TẦM NHÌN<br>Triết lý và Tầm nhìn Quản lý',
    companyProfile: 'HỒ SƠ CÔNG TY<br>Tổng quan Công ty',
    welfare: 'PHÚC LỢI<br>Phúc lợi',
    // NavBar
    achievementsNavBar: 'Thành tựu',
    companyNavBar: 'Tất cả thông tin công ty',
    visionNavBar: 'Triết lý và Tầm nhìn Quản lý',
    companyProfileNavBar: 'Tổng quan Công ty',
    welfareNavBar: 'Phúc lợi',
    solution: 'Tất cả nội dung kinh doanh',
    ses: 'Kinh doanh SES',
    appDev: 'Phát triển Ứng dụng',
    dispatch: 'Hỗ trợ nhân sự ngành khác',
    // Footer
    companyNameFooter: 'Công ty TNHH<br />GrowthSoft',
    address: 'Số 1-7-10 Iidabashi, Chiyoda-ku, Tokyo 102-0072<br />Tầng 7, Tòa nhà Yamakyu Main Building',
    phone: 'Điện thoại: 03-6265-4936',
    email: 'Email: info@growthsoft.co.jp',
    links: 'Liên kết',
    home: 'Trang chủ',
    companyInfo: 'Thông tin công ty',
    recruitmentInfo: 'Thông tin tuyển dụng',
    contact: 'Liên hệ',
    copyright: 'Bản quyền © 2024. Công ty TNHH GrowthSoft. Bảo lưu mọi quyền.',
    // Greeting Section
    greetingTitle: 'Lời chào',
    greetingMission: '<strong>Sứ mệnh và Mục tiêu</strong><br>Tại GrowthSoft, ưu tiên hàng đầu của chúng tôi là sự thành công của doanh nghiệp khách hàng, và chúng tôi cam kết cung cấp các giải pháp CNTT sáng tạo. Sử dụng kinh nghiệm và chuyên môn phong phú của chúng tôi trong các ngành công nghiệp khác nhau, chúng tôi nỗ lực cung cấp các giải pháp tốt nhất.',
    greetingService: '<strong>Tính linh hoạt và Quan hệ tin cậy của Dịch vụ</strong><br>Chúng tôi cung cấp các dịch vụ linh hoạt và hiệu quả phù hợp với nhu cầu của khách hàng, luôn vượt qua sự mong đợi của họ. Chúng tôi coi trọng việc xây dựng quan hệ tin cậy thông qua việc giới thiệu các công nghệ tiên tiến và cải tiến liên tục.',
    greetingEmployee: '<strong>Sự phát triển và Đóng góp của Nhân viên</strong><br>Thành công của chúng tôi được hỗ trợ bởi sự phát triển và đóng góp của từng nhân viên. Chúng tôi tạo ra một môi trường mà nhân viên có thể phát huy tối đa khả năng của mình và tiếp tục phát triển cùng nhau, hướng tới sự phát triển của toàn bộ tổ chức. Với tinh thần tôn trọng và hợp tác, chúng tôi đóng góp vào sự thành công của khách hàng.',
    greetingFuture: '<strong>Thách thức và Tạo ra Giá trị cho Tương lai</strong><br>Trong một thế giới công nghệ tiến bộ từng ngày, chúng tôi luôn thách thức bản thân và nỗ lực tạo ra giá trị mới. Chúng tôi hứa sẽ cùng khách hàng mở ra những tương lai mới. Chúng tôi chân thành mong nhận được sự ủng hộ và yêu thương từ các bạn.',
    ceoName: 'Vương Cường',
    // Vision Section
    visionTitle: 'Triết lý và Tầm nhìn Quản lý',
    visionMission: '<strong>Sứ mệnh và Mục tiêu</strong><br>Triết lý quản lý của chúng tôi là phát triển và đạt được cuộc sống hạnh phúc bằng cách tôn trọng lẫn nhau với khách hàng, nhân viên và xã hội nói chung. Chúng tôi luôn tuân thủ các tiêu chuẩn đạo đức cao và đóng góp vào sự phát triển bền vững của xã hội bằng cách xây dựng lòng tin.',
    visionService: '<strong>Tính linh hoạt và Quan hệ tin cậy của Dịch vụ</strong><br>Chúng tôi cung cấp công nghệ và dịch vụ vượt trội cho khách hàng, đề xuất các giải pháp vượt qua sự mong đợi của họ. Chúng tôi cung cấp cơ hội phát triển và môi trường làm việc thoải mái cho nhân viên, tôn trọng con đường sự nghiệp của từng người. Chúng tôi chú trọng bảo vệ môi trường và đóng góp cho cộng đồng, thực hiện kinh doanh bền vững.',
    visionEmployee: '<strong>Sự phát triển và Đóng góp của Nhân viên</strong><br>Chúng tôi nỗ lực đạt được cuộc sống hạnh phúc trong sự tôn trọng lẫn nhau và phát triển cùng nhau. Để thực hiện tầm nhìn này, chúng tôi tiếp cận công việc hàng ngày với sự chân thành và đam mê.',
    // Company Profile Section
    companyProfileTitle: 'Tổng quan Công ty',
    companyNameLabel: 'Tên công ty',
    companyName: 'Công ty TNHH GrowthSoft',
    capitalLabel: 'Vốn',
    capital: '5,000,000 JPY',
    ceoLabel: 'Đại diện',
    foundedLabel: 'Thành lập',
    founded: 'Ngày 4 tháng 9 năm 2023',
    employeesLabel: 'Số lượng nhân viên',
    employees: '10',
    headquartersLabel: 'Trụ sở chính',
    headquarters: 'Số 1-7-10 Iidabashi, Chiyoda-ku, Tokyo 102-0072, Tòa nhà Yamakyu Main Building, Tầng 7',
    phoneLabel: 'Điện thoại',
    phone: '03-6265-4936',
    invoiceLabel: 'Hóa đơn đủ điều kiện<br>Doanh nghiệp phát hành',
    invoiceNumber: 'T8013301051315<br>Từ ngày 1 tháng 6 năm 2024',
    // Welfare Section
    welfareTitle: 'Phúc lợi',
    insurance: 'Bảo hiểm',
    insuranceDetails: 'Bảo hiểm y tế, Lương hưu phúc lợi, Bảo hiểm thất nghiệp, Bảo hiểm tai nạn lao động, Đóng góp nuôi dưỡng trẻ em',
    vacation: 'Kỳ nghỉ',
    vacationDetails: 'Nghỉ thai sản, Nghỉ chăm sóc con, Hệ thống nghỉ phép chăm sóc, Hệ thống thời gian làm việc linh hoạt, Hệ thống làm việc ngắn hạn (Mang thai, Chăm sóc con, Chăm sóc)',
    allowances: 'Phụ cấp',
    allowancesDetails: 'Phụ cấp đi lại, Phụ cấp chuyên cần, Phụ cấp làm thêm giờ, Phụ cấp nhà ở, Phụ cấp đặc biệt cuối năm, Hỗ trợ đạt chứng chỉ, Hỗ trợ chi phí hội thảo',
    // Other
    home: 'Trang chủ',
    solutions: 'Nội dung dịch vụ',
    contact: 'Liên hệ',
    language: 'Ngôn ngữ',
    languages: {
      ja: '日本語',
      zh: '中文',
      en: 'English',
      ko: '한국어',
      vi: 'Tiếng Việt',
    },
    recruitment: {
      bannerTitle: 'Chúng tôi đang tuyển dụng các thành viên thách thức tạo ra giá trị bằng sức mạnh công nghệ',
      bannerButton: 'Xem chi tiết',
      title: 'Thông tin tuyển dụng',
      description: 'GrowthSoft đang nhận đơn đăng ký cho các vị trí sau.<br>Nhấp vào tên công việc để xem chi tiết.',
      applyButton: 'Ứng tuyển',
      newGraduate: 'Tuyển dụng sinh viên mới tốt nghiệp',
      careerRecruitment: 'Tuyển dụng giữa kỳ',
      job1: {
        title: 'Tuyển dụng sinh viên mới tốt nghiệp năm 2025 (Sinh viên tốt nghiệp lần thứ hai)',
        details: [
          {
            title: 'Điều kiện ứng tuyển',
            content: [
              {
                label: 'Điều kiện bắt buộc',
                value: `
                  <li>Bằng đại học trở lên (Chuyên ngành liên quan đến CNTT đối với sinh viên tốt nghiệp trường cao đẳng kỹ thuật)</li>
                  <li>Tính cách tốt và kỹ năng giao tiếp</li>
                  <li>Khả năng làm việc hợp tác trong nhóm</li>
                `
              }
            ]
          },
          {
            title: 'Đãi ngộ',
            content: [
              {
                label: 'Thu nhập hàng năm dự kiến',
                value: '3 triệu đến 4 triệu JPY<br>※Ưu đãi theo khả năng'
              },
              {
                label: '',
                value: `
                  <li>Quyết định theo quy định công ty, xem xét các điều kiện khác nhau</li>
                  <li>Thời gian thử việc: Có (3 tháng, không thay đổi điều kiện làm việc)</li>
                  <li>Tăng lương: Một lần mỗi năm</li>
                  <li>Thưởng: Hai lần mỗi năm (có thể có thưởng quyết toán)</li>
                `
              }
            ]
          },
          {
            title: 'Địa điểm làm việc',
            content: 'Tokyo/Kanagawa/Chiba/Saitama'
          }
        ]
      },
      job2: {
        title: 'Kỹ sư hệ thống',
        details: [
          {
            title: 'Mô tả công việc',
            content: 'Bạn sẽ tham gia vào công việc phát triển hệ thống như một kỹ sư hệ thống (định nghĩa yêu cầu, thiết kế cơ bản, thiết kế chi tiết, phát triển, thử nghiệm, bảo trì) tại các địa điểm phát triển của khách hàng (công ty kinh doanh, nhà cung cấp dịch vụ hệ thống của nhà sản xuất, nhà cung cấp dịch vụ hệ thống của người dùng, v.v.).'
          },
          {
            title: 'Điều kiện ứng tuyển',
            content: [
              {
                label: 'Điều kiện bắt buộc',
                value: `
                  <li>Có ít nhất 1 năm kinh nghiệm phát triển hệ thống</li>
                  <li>Có kinh nghiệm lập trình và thành thạo các ngôn ngữ như Java, Python, C++, v.v.</li>
                `
              },
              {
                label: 'Kinh nghiệm và kỹ năng mong muốn',
                value: `
                  <li>Có kinh nghiệm vận hành và bảo trì hệ thống trong quá khứ, có kinh nghiệm phát triển gần đây</li>
                  <li>Có kinh nghiệm PM, PL trong các quy trình thượng nguồn hoặc có kinh nghiệm đào tạo nhân viên mới</li>
                  <li>Có kinh nghiệm phát triển ứng dụng web hoặc ứng dụng di động</li>
                  <li>Có kinh nghiệm thiết kế và quản lý cơ sở dữ liệu</li>
                `
              }
            ]
          },
          {
            title: 'Đãi ngộ',
            content: [
              {
                label: 'Thu nhập hàng năm dự kiến',
                value: '5 triệu đến 10 triệu JPY<br>※Ưu đãi theo kinh nghiệm'
              },
              {
                label: '',
                value: `
                  <li>Quyết định theo quy định công ty, xem xét khả năng, kinh nghiệm, tuổi tác, v.v.</li>
                  <li>Thời gian thử việc: Có (3 tháng, không thay đổi điều kiện làm việc)</li>
                  <li>Tăng lương: Một lần mỗi năm</li>
                  <li>Thưởng: Hai lần mỗi năm (có thể có thưởng quyết toán)</li>
                `
              }
            ]
          },
          {
            title: 'Địa điểm làm việc',
            content: 'Tokyo/Kanagawa/Chiba/Saitama'
          }
        ]
      },
      job3: {
        title: 'Hỗ trợ nhân sự ngành khác',
        details: [
          {
            title: 'Điều kiện ứng tuyển',
            content: [
              {
                label: 'Điều kiện bắt buộc',
                value: `
                  <li>Tính cách tốt và kỹ năng giao tiếp mạnh</li>
                  <li>Khả năng làm việc hợp tác trong nhóm</li>
                  <li>Khả năng thực hiện công việc nhanh chóng và chính xác</li>
                  <li>Khả năng thích ứng với môi trường mới</li>
                `
              }
            ]
          },
          {
            title: 'Đãi ngộ',
            content: [
              {
                label: 'Thu nhập hàng năm dự kiến',
                value: '2.8 triệu đến 4.5 triệu JPY<br>※Ưu đãi theo kinh nghiệm'
              },
              {
                label: '',
                value: `
                  <li>Quyết định theo quy định công ty, xem xét khả năng, kinh nghiệm, tuổi tác, v.v.</li>
                  <li>Thời gian thử việc: Có (3 tháng, không thay đổi điều kiện làm việc)</li>
                  <li>Tăng lương: Một lần mỗi năm</li>
                  <li>Thưởng: Hai lần mỗi năm (có thể có thưởng quyết toán)</li>
                `
              }
            ]
          },
          {
            title: 'Địa điểm làm việc',
            content: 'Tokyo/Kanagawa/Chiba/Saitama'
          }
        ]
      }
    },
    contactUs: {
      title: 'Liên hệ',
      inquiryType: 'Loại hình liên hệ',
      name: 'Tên',
      lastName: 'Họ',
      firstName: 'Tên',
      companyName: 'Tên công ty hoặc tổ chức',
      departmentName: 'Tên bộ phận',
      email: 'Địa chỉ email',
      phone: 'Số điện thoại',
      message: 'Nội dung liên hệ',
      submitButton: 'Gửi',
      required: 'Bắt buộc',
      optional: 'Tùy chọn',
      select: 'Vui lòng chọn',
      newGraduate: 'Tuyển dụng sinh viên mới tốt nghiệp',
      careerRecruitment: 'Tuyển dụng giữa kỳ',
      serviceInquiry: 'Liên hệ về dịch vụ hoặc công ty',
      other: 'Khác',
      validationMessage: 'Vui lòng điền vào trường này.',
      successMessage: 'Tin nhắn đã được gửi!',
      errorMessage: 'Gửi tin nhắn thất bại. Vui lòng thử lại.'
    },
    solutionInfo: {
      title: 'Nội dung dịch vụ',
      overview: 'Tổng quan',
      technicalFields: 'Lĩnh vực kỹ thuật',
      services: 'Nội dung dịch vụ',
      ses: {
        title: 'Kinh doanh SES',
        description: 'Chúng tôi tập trung vào kinh doanh SES (Dịch vụ Kỹ thuật Hệ thống), cung cấp các dịch vụ toàn diện từ phát triển hệ thống đến vận hành và bảo trì. Chúng tôi có thể đáp ứng các nhu cầu đa dạng từ giai đoạn phát triển dự án đến di chuyển, bảo trì và vận hành. Ngoài ra, chúng tôi có nhiều kỹ sư nước ngoài xuất sắc, cung cấp dịch vụ chất lượng cao và hiệu quả về chi phí. Hãy liên hệ với chúng tôi để được tư vấn báo giá.',
        frontend: 'Frontend',
        languages: 'Ngôn ngữ phát triển',
        database: 'Cơ sở dữ liệu',
        frontendContent: 'Vue.js, React.js, Angular.js, v.v.',
        languagesContent: 'Java, C, C++, C#, JavaScript, TypeScript, VB.NET, ASP.NET, COBOL, PHP, v.v.',
        databaseContent: 'Oracle, MySQL, PostgreSQL, SQLserver, MongoDB, v.v.'
      },
      appDev: {
        title: 'Phát triển Ứng dụng',
        description: 'Trong kinh doanh phát triển ứng dụng, chúng tôi lập kế hoạch, phát triển và vận hành các ứng dụng điện thoại thông minh và ứng dụng web. Chúng tôi cung cấp các ứng dụng thân thiện với người dùng sử dụng công nghệ tiên tiến nhất.',
        services: 'Chúng tôi cung cấp hỗ trợ toàn diện từ định nghĩa yêu cầu, thiết kế, phát triển, thử nghiệm đến phát hành. Chúng tôi phát triển các ứng dụng tối ưu hóa cho mô hình kinh doanh của khách hàng, nâng cao tính cạnh tranh của họ.'
      },
      dispatch: {
        title: 'Hỗ trợ nhân sự ngành khác',
        description: 'Dịch vụ hỗ trợ nhân sự ngành khác cung cấp nhân viên xuất sắc với kỹ năng và kinh nghiệm cần thiết cho các công ty trong các ngành công nghiệp khác nhau. Chúng tôi nhanh chóng và chính xác kết nối nhân sự tốt nhất theo nhu cầu kinh doanh của khách hàng. Các ví dụ về ngành bao gồm khách sạn, nhà máy và nhà hàng.',
        services: 'Chúng tôi cung cấp dịch vụ toàn diện từ tuyển dụng, lựa chọn nhân sự đến theo dõi sau khi bắt đầu làm việc. Chúng tôi hỗ trợ sự thành công của khách hàng bằng cách cung cấp nhân sự phù hợp dựa trên nhu cầu kinh doanh của họ.'
      }
    }
  },
};

const i18n = new VueI18n({
  locale: 'ja', // 设置默认语言
  messages,
});

export default i18n;
