<template>
  <div v-if="isSideNavOpen" class="side-nav-overlay" @click="closeSideNav">
    <div class="side-nav" @click.stop>
      <ul class="side-nav-links">
        <li @click="navigateToHome">{{ $t('home') }}</li>
        <li @click.stop="toggleDropdown('company')">
          <div class="dropdown-toggle">
            {{ $t('companyInfo') }}
            <span class="arrow" :class="{ 'arrow-up': showDropdown.company, 'arrow-down': !showDropdown.company }"></span>
          </div>
          <transition name="slide-fade">
            <ul v-if="showDropdown.company">
              <li class="dropdown-item" @click.stop="navigateToSection('companyInfo', '')">{{ $t('companyNavBar') }}</li>
              <li class="dropdown-item" @click.stop="navigateToSection('companyInfo', 'vision')">{{ $t('visionNavBar') }}</li>
              <li class="dropdown-item" @click.stop="navigateToSection('companyInfo', 'companyprofile')">{{ $t('companyProfileNavBar') }}</li>
              <li class="dropdown-item" @click.stop="navigateToSection('companyInfo', 'welfare')">{{ $t('welfareNavBar') }}</li>
            </ul>
          </transition>
        </li>
        <li @click.stop="toggleDropdown('solution')">
          <div class="dropdown-toggle">
            {{ $t('solutions') }}
            <span class="arrow" :class="{ 'arrow-up': showDropdown.solution, 'arrow-down': !showDropdown.solution }"></span>
          </div>
          <transition name="slide-fade">
            <ul v-if="showDropdown.solution">
              <li class="dropdown-item" @click.stop="navigateToSection('solutionInfo', '')">{{ $t('solution') }}</li>
              <li class="dropdown-item" @click.stop="navigateToSection('solutionInfo', 'SES')">{{ $t('ses') }}</li>
              <li class="dropdown-item" @click.stop="navigateToSection('solutionInfo', 'AppDev')">{{ $t('appDev') }}</li>
              <li class="dropdown-item" @click.stop="navigateToSection('solutionInfo', 'Dispatch')">{{ $t('dispatch') }}</li>
            </ul>
          </transition>
        </li>
        <li @click="navigateToSection('contactInfo', '')">{{ $t('contact') }}</li>
        <li @click="navigateToSection('recruitmentInfo', '')">{{ $t('recruitmentInfo') }}</li>
        <li @click.stop="toggleDropdown('languages')">
          <div class="dropdown-toggle">
            language
            <span class="arrow" :class="{ 'arrow-up': showDropdown.languages, 'arrow-down': !showDropdown.languages }"></span>
          </div>
          <transition name="slide-fade">
            <ul v-if="showDropdown.languages">
              <li class="dropdown-item" v-for="(value, key) in languages" :key="key" @click="changeLanguage(key)">{{ value }}</li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isSideNavOpen'],
  data() {
    return {
      showDropdown: {
        company: false,
        languages: false,
        solution: false,
      },
      languages: this.$i18n.messages[this.$i18n.locale].languages,
    };
  },
  watch: {
    '$i18n.locale'() {
      this.languages = this.$i18n.messages[this.$i18n.locale].languages;
    }
  },
  methods: {
    closeSideNav() {
      this.$emit('closeSideNav');
      // 关闭所有的下拉菜单
      this.showDropdown.company = false;
      this.showDropdown.languages = false;
      this.showDropdown.solution = false;
    },
    navigateToSection(router, subSectionId) {
      const targetPath = `/${router}`;
      const hash = subSectionId ? `#${subSectionId}` : '';
      const currentPath = this.$route.path;
      const currentHash = window.location.hash;

      this.closeSideNav();

      if (currentPath !== targetPath) {
        this.$router.push(targetPath).then(() => {
          if (subSectionId) {
            location.hash = hash;
          }
        });
      } else if (currentHash !== hash) {
        this.$router.replace({ hash: hash }).then(() => {
          const element = document.getElementById(subSectionId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        });
      } else if (subSectionId && currentHash === hash) {
        const element = document.getElementById(subSectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    navigateToHome() {
      this.closeSideNav();
      if (this.$route.path !== '/') {
        this.$router.push('/');
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      sessionStorage.setItem('language', lang);
      this.closeSideNav();
    },
    toggleDropdown(section) {
      this.showDropdown[section] = !this.showDropdown[section];
    }
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.side-nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow-y: auto; /* 允许竖向滚动 */
  -webkit-overflow-scrolling: touch; /* 使滚动平滑 */
}

.side-nav {
  font-family: 'Noto Sans JP', sans-serif;
  width: 75vw;
  height: auto;
  max-height: 80vh; /* 设置最大高度，允许滚动 */
  overflow-y: scroll; /* 允许内容溢出时滚动 */
  -ms-overflow-style: none;  /* IE和Edge */
  scrollbar-width: none;  /* Firefox */
  background-color: white;
  padding: 20px;
  border: 1px #414c64 solid;
  color: #414c64;
  border-radius: 5px;
  position: relative;
}

.side-nav::-webkit-scrollbar {
  display: none; /* Chrome, Safari 和 Opera */
}

.side-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.side-nav-links {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
}

.side-nav-links li {
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  user-select: none; /* 禁止文本选中 */
}

.side-nav-links li ul {
  list-style: none;
  padding-left: 24px;
  user-select: none; /* 禁止文本选中 */
  margin-top: 25px;
}

li:first-child {
  padding-top: 0;
}

.dropdown-item:last-child {
  border-bottom: none;
  padding-bottom: 10px;
}

.arrow {
  display: inline-block;
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #414c64;
}

.arrow-up {
  transform: rotate(180deg);
}

.arrow-down {
  transform: rotate(0deg);
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.side-nav-links li:focus,
.side-nav-links li:active,
.side-nav-links li ul li:focus,
.side-nav-links li ul li:active {
  background-color: transparent; /* 禁止背景色变化 */
  outline: none; /* 去除焦点边框 */
}

.dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
</style>
